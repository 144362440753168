import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IoClose } from "react-icons/io5";
import "./ContractDetailsPopup.css";
import { getCookie } from "../../../../../cookieUtils";
import {
  ContractElectricityDetails,
  NetCostsZipCodeElectricity,
} from "../../../../../interfaces/interfaces";

interface ContractDetailsProps {
  contractId: string;
  onClose: () => void;
}

const ContractDetailsPopup: React.FC<ContractDetailsProps> = ({
  contractId,
  onClose,
}) => {
  const [contractDetails, setContractDetails] =
    useState<ContractElectricityDetails | null>(null);
  const [netCostsZipCodeElectricity, setNetCostsZipCodeElectricity] =
    useState<NetCostsZipCodeElectricity | null>(null);
  const zipcode = getCookie("postcode");
  const excludedVerbruikValue = getCookie("verbruikValue");
  const isCheckedAlt = getCookie("isCheckedAlt");
  const excludedNettoAfnameDag = getCookie("nettoAfnameDag");
  const excludedNettoAfnameWeekend = getCookie("nettoAfnameWeekend");
  const excludedNettoAfnameUitNacht = getCookie("nettoAfnameUitNacht");
  const excludedOmvormersVermogen = getCookie("omvormersVermogen");
  const hasDigitalMeter = getCookie("hasDigitalMeter");
  const hasSolarPanels = getCookie("hasSolarPanels");

  const verbruikValue1 = excludedVerbruikValue
    ? parseFloat(excludedVerbruikValue)
    : 0;
  const nettoAfnameDag = excludedNettoAfnameDag
    ? parseFloat(excludedNettoAfnameDag)
    : 0;
  const nettoAfnameWeekend = excludedNettoAfnameWeekend
    ? parseFloat(excludedNettoAfnameWeekend)
    : 0;
  const nettoAfnameUitNacht = excludedNettoAfnameUitNacht
    ? parseFloat(excludedNettoAfnameUitNacht)
    : 0;
  const omvormersVermogen = excludedOmvormersVermogen
    ? parseFloat(excludedOmvormersVermogen)
    : 0;

  const verbruikValue =
    (verbruikValue1 || 0) +
    (nettoAfnameDag || 0) +
    (nettoAfnameWeekend || 0) +
    (nettoAfnameUitNacht || 0);

  const fetchContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/ContractElectricityDetails/${contractId}`,
      );
      setContractDetails(response.data);
    } catch (error) {
      console.error("Error retrieving contract details:", error);
      // Handle error state or display an error message
    }
  };

  const fetchNetCostsZipCodeElectricity = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/netCosts/${zipcode}`,
      );
      setNetCostsZipCodeElectricity(response.data);
    } catch (error) {
      console.error("Error retrieving zipcode details:", error);
      // Handle error state or display an error message
    }
  };

  useEffect(() => {
    fetchContractDetails();
    fetchNetCostsZipCodeElectricity();
  }, [contractId]);

  if (!contractDetails) {
    // Display a loading state while fetching the contract details
    return <div>Loading...</div>;
  }

  const tableRows = [
    {
      key: "Jaarlijks abonnement",
      label: "Jaarlijks abonnement",
      unitPrice: "",
      totalPrice: `${contractDetails.yearlySubscription?.toFixed(2) ?? 0} €`,
      condition: true,
    },
    {
      key: "kWh verbruikskost",
      label: "kWh verbruikskost",
      unitPrice: `${contractDetails.kwhConsumptionPrice ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue1 * (contractDetails.kwhConsumptionPrice ?? 0)).toFixed(2)} €`,
      condition: isCheckedAlt === "isChecked1Alt",
    },
    {
      key: "Tweevoudig tarief (dag)",
      label: "Tweevoudig tarief (dag)",
      unitPrice: `${contractDetails.kwhConsumptionPriceTwoDay ?? 0} €/kWh`,
      totalPrice: `${(nettoAfnameDag * (contractDetails.kwhConsumptionPriceTwoDay ?? 0)).toFixed(2)} €`,
      condition:
        isCheckedAlt === "isChecked2Alt" || isCheckedAlt === "isChecked4Alt",
    },
    {
      key: "Tweevoudig tarief (nacht)",
      label: "Tweevoudig tarief (nacht)",
      unitPrice: `${contractDetails.kwhConsumptionPriceTwoNight ?? 0} €/kWh`,
      totalPrice: `${(nettoAfnameWeekend * (contractDetails.kwhConsumptionPriceTwoNight ?? 0)).toFixed(2)} €`,
      condition:
        isCheckedAlt === "isChecked2Alt" || isCheckedAlt === "isChecked4Alt",
    },
    {
      key: "Exclusief nacht tarief",
      label: "Exclusief nacht tarief",
      unitPrice: `${contractDetails.kwhConsumptionPriceNight ?? 0} €/kWh`,
      totalPrice: `${(nettoAfnameUitNacht * (contractDetails.kwhConsumptionPriceNight ?? 0)).toFixed(2)} €`,
      condition:
        isCheckedAlt === "isChecked3Alt" || isCheckedAlt === "isChecked4Alt",
    },
    {
      key: "Bijdrage hernieuwbare energie + wwk",
      label: 'Bijdrage "hernieuwbare energie + wwk"',
      unitPrice: `${contractDetails.hewwkContribution ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (contractDetails.hewwkContribution ?? 0)).toFixed(2)} €`,
      condition: true,
    },
    // Nettarieven divider row (styled differently, no prices)
    {
      key: "Nettarieven",
      label: "Nettarieven",
      unitPrice: "",
      totalPrice: "",
      condition: true,
      isDivider: true, // Additional flag for styling purposes
    },
    {
      key: "Tarief Databeheer",
      label: "Tarief Databeheer",
      unitPrice: "",
      totalPrice: `${netCostsZipCodeElectricity?.dataManagementPrice?.toFixed(2) ?? 0} €`,
      condition: netCostsZipCodeElectricity?.dataManagementPrice !== 0,
    },
    {
      key: "Capaciteitstarief Dm",
      label: "Capaciteitstarief Dm",
      unitPrice: "",
      totalPrice: `${netCostsZipCodeElectricity?.capacityRatePriceDm?.toFixed(2) ?? 0} €`,
      condition:
        hasDigitalMeter === "Ja" &&
        netCostsZipCodeElectricity?.capacityRatePriceDm !== 0,
    },
    {
      key: "Afnametarief Dm ",
      label: "Afnametarief Dm",
      unitPrice: `${netCostsZipCodeElectricity?.purchaseRatePriceDm ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeElectricity?.purchaseRatePriceDm ?? 0)).toFixed(2)} €`,
      condition: hasDigitalMeter === "Ja" && isCheckedAlt !== "isChecked3Alt",
    },
    {
      key: "Afnametarief excl nacht Dm",
      label: "Afnametarief excl nacht Dm",
      unitPrice: `${netCostsZipCodeElectricity?.purchaseRateNightPriceDm ?? 0} €/kWh`,
      totalPrice: `${(nettoAfnameUitNacht * (netCostsZipCodeElectricity?.purchaseRateNightPriceDm ?? 0)).toFixed(2)} €`,
      condition:
        hasDigitalMeter === "Ja" &&
        (isCheckedAlt === "isChecked3Alt" || isCheckedAlt === "isChecked4Alt"),
    },
    {
      key: "Capaciteitstarief",
      label: "Capaciteitstarief",
      unitPrice: "",
      totalPrice: `${netCostsZipCodeElectricity?.capacityRatePrice?.toFixed(2) ?? 0} €`,
      condition:
        hasDigitalMeter === "Nee" &&
        netCostsZipCodeElectricity?.capacityRatePrice !== 0,
    },
    {
      key: "Afnametarief",
      label: "Afnametarief",
      unitPrice: `${netCostsZipCodeElectricity?.purchaseRatePrice ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeElectricity?.purchaseRatePrice ?? 0)).toFixed(2)} €`,
      condition:
        hasDigitalMeter === "Nee" &&
        isCheckedAlt !== "isChecked3Alt" &&
        isCheckedAlt !== "isChecked4Alt",
    },
    {
      key: "Afnametarief excl nacht",
      label: "Afnametarief excl nacht",
      unitPrice: `${netCostsZipCodeElectricity?.purchaseRateNightPrice ?? 0} €/kWh`,
      totalPrice: `${(nettoAfnameUitNacht * (netCostsZipCodeElectricity?.purchaseRateNightPrice ?? 0)).toFixed(2)} €`,
      condition:
        hasDigitalMeter === "Nee" &&
        (isCheckedAlt === "isChecked3Alt" || isCheckedAlt === "isChecked4Alt"),
    },
    {
      key: "Prosumenten tarief",
      label: "Prosumenten tarief",
      unitPrice: `${netCostsZipCodeElectricity?.prosumentPrice ?? 0} €/kWh`,
      totalPrice: `${(omvormersVermogen * (netCostsZipCodeElectricity?.prosumentPrice ?? 0)).toFixed(2)} €`,
      condition: hasSolarPanels === "JA" && hasDigitalMeter === "Nee",
    },
    {
      key: "Distributiekosten Dagteller",
      label: "Distributiekosten Dagteller",
      unitPrice: `${netCostsZipCodeElectricity?.distributionDayPrice ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeElectricity?.distributionDayPrice ?? 0)).toFixed(2)} €`,
      condition: netCostsZipCodeElectricity?.distributionDayPrice !== 0,
    },
    {
      key: "Distributiekosten Dag- en nachtteller (Dag)",
      label: "Distributiekosten Dag- en nachtteller (Dag)",
      unitPrice: `${netCostsZipCodeElectricity?.ditributionCombiDayPrice ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeElectricity?.ditributionCombiDayPrice ?? 0)).toFixed(2)} €`,
      condition: netCostsZipCodeElectricity?.ditributionCombiDayPrice !== 0,
    },
    {
      key: "Distributiekosten Dag- en nachtteller (Nacht)",
      label: "Distributiekosten Dag- en nachtteller (Nacht)",
      unitPrice: `${netCostsZipCodeElectricity?.ditributionCombiNightPrice ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeElectricity?.ditributionCombiNightPrice ?? 0)).toFixed(2)} €`,
      condition: netCostsZipCodeElectricity?.ditributionCombiNightPrice !== 0,
    },
    {
      key: "Distributiekosten Exclusief nacht",
      label: "Distributiekosten Exclusief nacht",
      unitPrice: `${netCostsZipCodeElectricity?.distributionNightPrice ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeElectricity?.distributionNightPrice ?? 0)).toFixed(2)} €`,
      condition: netCostsZipCodeElectricity?.distributionNightPrice !== 0,
    },
    {
      key: "Transportkosten",
      label: "Transportkosten",
      unitPrice: `${netCostsZipCodeElectricity?.transportCostPrice ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeElectricity?.transportCostPrice ?? 0)).toFixed(2)} €`,
      condition: netCostsZipCodeElectricity?.transportCostPrice !== 0,
    },
    {
      key: "Tarief databeheer / Meet- en telactiviteit",
      label: "Tarief databeheer / Meet- en telactiviteit",
      unitPrice: "",
      totalPrice: `${netCostsZipCodeElectricity?.DNB?.toFixed(2) ?? 0} €`,
      condition: netCostsZipCodeElectricity?.DNB !== 0,
    },
    // Heffingen divider row (styled differently, no prices)
    {
      key: "Heffingen",
      label: "Heffingen",
      unitPrice: "",
      totalPrice: "",
      condition: true,
      isDivider: true, // Additional flag for styling purposes
    },
    {
      key: "Bijzondere accijns",
      label: "Bijzondere accijns",
      unitPrice: `${netCostsZipCodeElectricity?.taxAccijns ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeElectricity?.taxAccijns ?? 0)).toFixed(2)} €`,
      condition: netCostsZipCodeElectricity?.taxAccijns !== 0,
    },
    {
      key: "Bijdrage op de energie",
      label: "Bijdrage op de energie",
      unitPrice: `${netCostsZipCodeElectricity?.contributionEnergy ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeElectricity?.contributionEnergy ?? 0)).toFixed(2)} €`,
      condition: netCostsZipCodeElectricity?.contributionEnergy !== 0,
    },
    {
      key: "Aansluitingsvergoeding",
      label: "Aansluitingsvergoeding",
      unitPrice: `${netCostsZipCodeElectricity?.connectionFee ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeElectricity?.connectionFee ?? 0)).toFixed(2)} €`,
      condition: netCostsZipCodeElectricity?.connectionFee !== 0,
    },
  ];

  const calculateTotalYearlyCost = () => {
    const {
      yearlySubscription,
      kwhConsumptionPrice,
      kwhConsumptionPriceTwoDay,
      kwhConsumptionPriceTwoNight,
      kwhConsumptionPriceNight,
      hewwkContribution,
    } = contractDetails;

    const total =
      yearlySubscription +
      (isCheckedAlt === "isChecked1Alt"
        ? kwhConsumptionPrice * verbruikValue1
        : 0) +
      (isCheckedAlt === "isChecked2Alt"
        ? kwhConsumptionPriceTwoDay * nettoAfnameDag +
          kwhConsumptionPriceTwoNight * nettoAfnameWeekend
        : 0) +
      (isCheckedAlt === "isChecked3Alt"
        ? kwhConsumptionPriceNight * nettoAfnameUitNacht
        : 0) +
      hewwkContribution +
      (netCostsZipCodeElectricity?.dataManagementPrice ?? 0) +
      (netCostsZipCodeElectricity?.purchaseRatePrice ?? 0) * verbruikValue +
      (netCostsZipCodeElectricity?.taxAccijns ?? 0) * verbruikValue +
      (netCostsZipCodeElectricity?.contributionEnergy ?? 0) * verbruikValue +
      (netCostsZipCodeElectricity?.capacityRatePrice ?? 0) +
      (netCostsZipCodeElectricity?.distributionDayPrice ?? 0) * verbruikValue1 +
      (netCostsZipCodeElectricity?.distributionNightPrice ?? 0) *
        nettoAfnameUitNacht +
      (netCostsZipCodeElectricity?.ditributionCombiDayPrice ?? 0) *
        nettoAfnameDag +
      (netCostsZipCodeElectricity?.ditributionCombiNightPrice ?? 0) *
        nettoAfnameWeekend +
      (netCostsZipCodeElectricity?.transportCostPrice ?? 0) * verbruikValue +
      (netCostsZipCodeElectricity?.DNB ?? 0);

    return total.toFixed(2);
  };

  return (
    <div className="popupContainer">
      <div className="popupOverlay" onClick={onClose}></div>
      <div className="popupContent">
        <TableContainer sx={{ border: "none" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h2>ContractDetails</h2>
                </TableCell>
                <TableCell align="right"> </TableCell>
                <TableCell align="right">
                  <IoClose size={20} onClick={onClose} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell align="right">
                  <b>Eenheidsprijs</b>
                </TableCell>
                <TableCell align="right">
                  <b>Prijs</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Energieprijs row */}
              <TableRow
                key="energieprijs"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Energieprijs</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {/* Map over dynamic tableRows with conditional rendering */}
              {tableRows
                .filter((row) => row.condition)
                .map((row) =>
                  row.isDivider ? (
                    <TableRow
                      key={row.key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <strong>{row.label}</strong>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={row.key}>
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="right">{row.unitPrice}</TableCell>
                      <TableCell align="right">{row.totalPrice}</TableCell>
                    </TableRow>
                  ),
                )}

              {/* Totale Jaarlijkse kost row */}
              <TableRow
                key="Totale kost verbruik"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Totale Jaarlijkse kost</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  {calculateTotalYearlyCost()} € incl. BTW
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ContractDetailsPopup;
