import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import NavBar from "../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../components/customer/NavigationCustomer";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, createTheme, ThemeProvider, Tooltip } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HomeIcon from "@mui/icons-material/Home";
import WeekendIcon from "@mui/icons-material/Weekend";
import HouseIcon from "@mui/icons-material/House";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import GroupsIcon from "@mui/icons-material/Groups";
import axios from "axios";
import Footer from "../../../../components/Footer/Footer";
import PageHero from "../../../../components/Pagehero/PageHero";
import "./ElectricityContractInput.css";
import SquareComponentIt from "../../../../components/InfoSquares/InfoSquaresIt/InfoSquaresIt";
import SquareComponent from "../../../../components/InfoSquares/InfoSquaresSales/InfoSquares";
import { getCookie, setCookie } from "../../../../cookieUtils";
import { green } from "@mui/material/colors";
import InfoIcon from "@mui/icons-material/Info";

interface EnergySupplier {
  id: number;
  name: string;
  active: number;
}

interface NetCostsZipCodeElectricity {
  zipcode: string;
  name: string;
  dataManagementPrice: number;
  capacityRatePriceDm: number;
  purchaseRatePriceDm: number;
  purchaseRateNightPriceDm: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  purchaseRateNightPrice: number;
  prosumentPrice: number;
  distributionDayPrice: number;
  ditributionCombiDayPrice: number;
  ditributionCombiNightPrice: number;
  distributionNightPrice: number;
  transportCostPrice: number;
  DNB: number;
  taxNonResidential: number;
  taxAccijns: number;
  contributionEnergy: number;
  connectionFee: number;
}

const ElectricityContractInput: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [selectedZipCode, setSelectedZipCode] = useState(""); // State for selected zip code
  const steps = ["Adres", "Verbruik", "Vergelijking", "Bijkomende informatie"];
  // const steps = ["Adres", "Verbruik", "Vergelijking"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [isChecked1Alt, setIsChecked1Alt] = useState(false);
  const [isChecked2Alt, setIsChecked2Alt] = useState(false);
  const [isChecked3Alt, setIsChecked3Alt] = useState(false);
  const [isChecked4Alt, setIsChecked4Alt] = useState(false);
  const [isCheckedCt1, setIsCheckedCt1] = useState(false);
  const [isCheckedCt2, setIsCheckedCt2] = useState(false);
  const [isCheckedCt3, setIsCheckedCt3] = useState(false);
  const [hasDigitalMeter, setHasDigitalMeter] = useState("Nee");
  const [hasSolarPanels, setHasSolarPanels] = useState("NEE");
  const [verbruikValue, setVerbruikValue] = useState("");
  const [nettoAfname, setNettoAfname] = useState("");
  const [nettoAfnameDag, setNettoAfnameDag] = useState("");
  const [nettoAfnameWeekend, setNettoAfnameWeekend] = useState("");
  const [nettoAfnameUitNacht, setNettoAfnameUitNacht] = useState("");
  const [omvormersVermogen, setOmvormersVermogen] = useState("");
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [netCostsZipCodeElectricity, setNetCostsZipCodeElectricity] = useState<
    NetCostsZipCodeElectricity[]
  >([]);
  const [supplierId, setSupplierId] = useState(
    "Selecteer een energieleverancier",
  );
  const [surname, setSurname] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [roleId, setRoleId] = useState<number | null>(null);
  const [selectedWoning, setSelectedWoning] = useState<string | null>(null);
  const [selectedResidents, setSelectedResidents] = useState<string | null>(
    null,
  );

  const navigate = useNavigate();

  const containerStyles: React.CSSProperties = {
    display: "flex",
    flexDirection: "column", // Change to column layout for mobile
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
    overflowX: "hidden", // Hide horizontal overflow on small screens
    alignItems: "flex-start", // To place the dynamic form on top
  };

  const squareContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "left", // Center the squares horizontally
    flexWrap: "wrap", // Allow squares to wrap to the next line if needed
    gap: "20px", // Add space between squares
    marginTop: "20px", // Add some top margin to the square container
  };

  const squareStyles: React.CSSProperties = {
    width: "150px",
    height: "100px",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  };

  const stepperStyles: React.CSSProperties = {
    maxWidth: "50%", // Ensure the stepper fits within the screen width
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchEnergySuppliers();
    fetchUserDataFromLocalStorage();
    fetchZipCodes();
  }, []);

  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      const suppliers: EnergySupplier[] = response.data;
      setEnergySuppliers(suppliers);
    } catch (error) {
      console.error("Error fetching energy suppliers:", error);
    }
  };

  const fetchZipCodes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/netCosts`,
      );
      const codes: NetCostsZipCodeElectricity[] = response.data;
      setNetCostsZipCodeElectricity(codes);
    } catch (error) {
      console.error("Error fetching Zipcodes:", error);
    }
  };

  const fetchUserDataFromLocalStorage = () => {
    const userData = getCookie("user");
    if (userData) {
      const { surname, name, email, phone } = JSON.parse(userData);
      setSurname(surname);
      setName(name);
      setEmail(email);
      setPhone(phone);
    }
  };

  const NavigateToElectricityContractOverview = () => {
    navigate("/ContractOverview/elektriciteit/multi/particulier");
  };

  const NavigateToElectricityContractOverviewVast = () => {
    navigate("/ContractOverview/elektriciteit/vast/particulier");
  };

  const NavigateToElectricityContractOverviewVariabel = () => {
    navigate("/ContractOverview/elektriciteit/variabel/particulier");
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleCheckbox1Change = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsChecked1(event.target.checked);
    setIsChecked2(false);
    setIsChecked3(false);
    setIsChecked4(false);
  };

  const handleCheckbox2Change = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsChecked1(false);
    setIsChecked2(event.target.checked);
    setIsChecked3(false);
    setIsChecked4(false);
  };

  const handleCheckbox3Change = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsChecked1(false);
    setIsChecked2(false);
    setIsChecked3(event.target.checked);
    setIsChecked4(false);
  };

  const handleCheckbox4Change = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsChecked1(false);
    setIsChecked2(false);
    setIsChecked3(false);
    setIsChecked4(event.target.checked);
  };

  const handleCheckbox1AltChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsChecked1Alt(event.target.checked);
    setIsChecked2Alt(false);
    setIsChecked3Alt(false);
    setIsChecked4Alt(false);
  };

  const handleCheckbox2AltChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsChecked1Alt(false);
    setIsChecked2Alt(event.target.checked);
    setIsChecked3Alt(false);
    setIsChecked4Alt(false);
  };

  const handleCheckbox3AltChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsChecked1Alt(false);
    setIsChecked2Alt(false);
    setIsChecked3Alt(event.target.checked);
    setIsChecked4Alt(false);
  };

  const handleCheckbox4AltChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsChecked1Alt(false);
    setIsChecked2Alt(false);
    setIsChecked3Alt(false);
    setIsChecked4Alt(event.target.checked);
  };

  const handleAltChange = (event: any) => {
    // console.log(event.target.value);
    const selectedValue = event.target.value;
    setCookie("isCheckedAlt", event.target.value, 2);
    if (selectedValue === "isChecked1Alt") {
      setIsChecked1Alt(true);
      setIsChecked2Alt(false);
      setIsChecked3Alt(false);
      setIsChecked4Alt(false);
    } else if (selectedValue === "isChecked2Alt") {
      setIsChecked1Alt(false);
      setIsChecked2Alt(true);
      setIsChecked3Alt(false);
      setIsChecked4Alt(false);
    } else if (selectedValue === "isChecked3Alt") {
      setIsChecked1Alt(false);
      setIsChecked2Alt(false);
      setIsChecked3Alt(true);
      setIsChecked4Alt(false);
    } else if (selectedValue === "isChecked4Alt") {
      setIsChecked1Alt(false);
      setIsChecked2Alt(false);
      setIsChecked3Alt(false);
      setIsChecked4Alt(true);
    }
  };

  const handleCheckboxCt1Change = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsCheckedCt1(event.target.checked);
    setIsCheckedCt2(false);
    setIsCheckedCt3(false);
  };

  const handleCheckbox2CtChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsCheckedCt1(false);
    setIsCheckedCt2(event.target.checked);
    setIsCheckedCt3(false);
  };

  const handleCheckboxCt3Change = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsCheckedCt1(false);
    setIsCheckedCt2(false);
    setIsCheckedCt3(event.target.checked);
  };

  const handleCtChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "isCheckedCt1") {
      setIsCheckedCt1(true);
      setIsCheckedCt2(false);
      setIsCheckedCt3(false);
    } else if (selectedValue === "isCheckedCt2") {
      setIsCheckedCt1(false);
      setIsCheckedCt2(true);
      setIsCheckedCt3(false);
    } else if (selectedValue === "isCheckedCt3") {
      setIsCheckedCt1(false);
      setIsCheckedCt2(false);
      setIsCheckedCt3(true);
    }
  };

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;
    if (name === "checkbox1") {
      setIsChecked1(checked);
      setIsChecked2(false);
    } else if (name === "checkbox2") {
      setIsChecked1(false);
      setIsChecked2(checked);
    }
  };

  // const handleDigitalMeterChange = (event: SelectChangeEvent<string>) => {
  //   setHasDigitalMeter(event.target.value);
  // };
  const handleDigitalMeterChange = (event: any) => {
    setHasDigitalMeter(event.target.value);
  };

  const handleSolarPanelsChange = (event: any) => {
    setHasSolarPanels(event.target.value);
  };

  const handleVerbruikChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerbruikValue(event.target.value);
  };

  // const handleVerbruiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setVerbruikValue(event.target.value);
  //   setCookie("isCheckedAlt", 'isChecked1Alt');
  // };

  const handleNettoAfnameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNettoAfname(event.target.value);
  };

  const handleNettoAfnameDagChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNettoAfnameDag(event.target.value);
  };

  const handleNettoAfnameWeekendChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNettoAfnameWeekend(event.target.value);
  };

  const handleNettoAfnameUitNachtChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNettoAfnameUitNacht(event.target.value);
  };

  const handleOmvormersVermogenchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOmvormersVermogen(event.target.value);
  };

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleUsageChange = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === "kv") {
      setIsChecked1(true);
      setIsChecked2(false);
    } else if (selectedValue === "kvn") {
      setIsChecked1(false);
      setIsChecked2(true);
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    // Save contact information to localStorage
    // setCookie(
    //   "contactgegevens",
    //   JSON.stringify({ surname, name, email, phone })
    // );

    if (activeStep === steps.length - 1) {
      // Last step, navigate to the contract overview page
      setCookie("verbruikValue", verbruikValue, 2);
      setCookie("nettoAfnameDag", nettoAfnameDag, 2);
      setCookie("nettoAfnameWeekend", nettoAfnameWeekend, 2);
      setCookie("nettoAfnameUitNacht", nettoAfnameUitNacht, 2);
      setCookie("omvormersVermogen", omvormersVermogen, 2);
      setCookie("hasDigitalMeter", hasDigitalMeter, 2);
      setCookie("hasSolarPanels", hasSolarPanels, 2);
      setCookie("supplierId", supplierId, 2);

      if (isCheckedCt1 === true) {
        NavigateToElectricityContractOverview();
      } else if (isCheckedCt2 === true) {
        NavigateToElectricityContractOverviewVast();
      } else if (isCheckedCt3 === true) {
        NavigateToElectricityContractOverviewVariabel();
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  // ... (existing code)

  const handleZipCodeChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const newZipCode = event.target.value as string;
    setCookie("postcode", newZipCode, 2);
    setSelectedZipCode(newZipCode);
  };

  const handleSupplierIdChange = (event: any) => {
    setSupplierId(event.target.value);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8dc63f",
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <>
        {roleId === 2 ? (
          <div>
            <NavBar toggleMenu={handleToggleMenu} />
            <PageHero>Energiecontract aanvragen</PageHero>
            <div className="pageContainerE" style={containerStyles}>
              <NavigationCustomer
                isMenuVisible={isMenuVisible}
                toggleMenu={handleToggleMenu}
              />
              <div className="parentContainer">
                <div
                  className="contentContainersolar"
                  style={contentContainerStyles}
                >
                  {/* <div className="contentContainersolar"> */}

                  <div className="userCardRequestsolar">
                    <div className="userCardRequestColumnssolar">
                      {/* Stepper */}

                      <Box sx={{ width: "100%" }}>
                        <div style={stepperStyles}>
                          <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                              const stepProps: { completed?: boolean } = {};
                              const labelProps: {
                                optional?: React.ReactNode;
                              } = {};
                              if (isStepSkipped(index)) {
                                stepProps.completed = false;
                              }
                              return (
                                <Step key={label} {...stepProps}>
                                  <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                              );
                            })}
                          </Stepper>
                        </div>
                        {/* Content for the active step */}

                        {activeStep === 0 && (
                          <div>
                            {/* Content for Step 1 (Address step) */}
                            <h2>Kies uw postcode</h2>
                            <p>
                              Gelieve uw postcode te selecteren in het menu:
                            </p>

                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  id="postcodeSelect"
                                  select
                                  label="Postcode"
                                  value={selectedZipCode}
                                  onChange={handleZipCodeChange}
                                  variant="standard"
                                  fullWidth
                                >
                                  {netCostsZipCodeElectricity.map(
                                    (netCostsZipCodeElectricity) => (
                                      <MenuItem
                                        key={netCostsZipCodeElectricity.zipcode}
                                        value={
                                          netCostsZipCodeElectricity.zipcode
                                        }
                                      >
                                        {netCostsZipCodeElectricity.zipcode},{" "}
                                        {netCostsZipCodeElectricity.name}
                                      </MenuItem>
                                    ),
                                  )}
                                </TextField>
                              </Box>
                            </div>
                          </div>
                        )}

                        {/* {activeStep === 0 && (
              <div>
              
                <h2>Kies uw postcode</h2>
                <p>Gelieve uw postcode te selecteren in het menu:</p>
               
                <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="postcodeSelect"
                    select
                    label="Postcode"
                    value={selectedZipCode}
                    onChange={handleZipCodeChange}
                    variant="standard"
                  >

{zipCodesBelgium.map((zipCode) => (
        <MenuItem key={zipCode} value={zipCode}>{zipCode}</MenuItem>
      ))}
                  </TextField>
                </Box>
              </div> 
               </div>
            )} */}
                        {/* <select value={selectedZipCode} onChange={handleZipCodeChange}>
                  <option value="">Selecteer uw postcode</option>
                  {zipCodesBelgium.map((zipCode) => (
                    <option key={zipCode} value={zipCode}>
                      {zipCode}
                    </option>
                  ))}
                </select>
              </div>
            )} */}

                        {activeStep === 1 && (
                          <div>
                            {/* Content for Step 2 (Verbruik step) */}

                            <div className="form-group">
                              <Box>
                                {/* <Typography variant="h4">Verbruik</Typography> */}
                                <h2>Verbruik</h2>
                                <Typography>
                                  Wanneer u een prijsaanvraag doet op basis van
                                  uw verbruik, zal dit correcter zijn dan een
                                  schatting van de prijssimulatie op basis van
                                  uw type woning.
                                </Typography>

                                <TextField
                                  id="Birthdatedd"
                                  fullWidth
                                  select
                                  label="Verbruik"
                                  onChange={(event) => handleUsageChange(event)}
                                  variant="standard"
                                  required
                                >
                                  <MenuItem value={"kv"}>
                                    Ik ken mijn verbruik (kWh)
                                  </MenuItem>
                                  <MenuItem value={"kvn"}>
                                    Ik wil een prijssimulatie doen op basis van
                                    mijn type woning
                                  </MenuItem>
                                </TextField>
                              </Box>
                            </div>
                            {/* <Box>
                  <Typography variant="h4">Verbruik</Typography>
                  <Typography>
                    Wanneer u een prijsaanvraag doet op basis van uw verbruik,
                    zal dit correcter zijn dan een schatting van de
                    prijssimulatie op basis van uw type woning.
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked1}
                        onChange={handleCheckboxChange}
                        name="checkbox1"
                      />
                    }
                    label="Ik ken mijn verbruik (kWh)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked2}
                        onChange={handleCheckboxChange}
                        name="checkbox2"
                      />
                    }
                    label="Ik wil een prijssimulatie doen op basis van mijn type woning"
                  />
                </Box> */}
                          </div>
                        )}

                        {activeStep === 2 && isChecked1 && (
                          <div>
                            {/* Content for Step 3 (Vergelijking step) */}
                            {/* <Typography variant="h6">Verbruiksgegevens</Typography> */}
                            <h2>Verbruiksgegevens Elektriciteit</h2>
                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  id="typemeter"
                                  select
                                  label="Type meter"
                                  // value={birthdatedd}
                                  onChange={(event) => handleAltChange(event)}
                                  variant="standard"
                                  fullWidth
                                >
                                  <MenuItem value={"isChecked1Alt"}>
                                    Dag (enkelvoudig tarief)
                                  </MenuItem>
                                  <MenuItem value={"isChecked2Alt"}>
                                    Dag/nacht (tweevoudig tarief)
                                  </MenuItem>
                                  <MenuItem value={"isChecked3Alt"}>
                                    Dag (enkelvoudig tarief) + uitsluitend nacht
                                  </MenuItem>
                                  <MenuItem value={"isChecked4Alt"}>
                                    Dag/nacht (tweevoudig tarief) + uitsluitend
                                    nacht
                                  </MenuItem>
                                </TextField>
                              </Box>
                            </div>

                            {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked1Alt}
                      onChange={handleCheckbox1AltChange}
                      name="checkbox1Alt"
                    />
                  }
                  label="Dag (enkelvoudig tarief)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked2Alt}
                      onChange={handleCheckbox2AltChange}
                      name="checkbox2Alt"
                    />
                  }
                  label="Dag/nacht (tweevoudig tarief)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked3Alt}
                      onChange={handleCheckbox3AltChange}
                      name="checkbox3Alt"
                    />
                  }
                  label="Dag (enkelvoudig tarief) + uitsluitend nacht"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked4Alt}
                      onChange={handleCheckbox4AltChange}
                      name="checkbox4Alt"
                    />
                  }
                  label="Dag/nacht (tweevoudig tarief) + uitsluitend nacht"
                /> */}

                            {/* <Typography variant="h6">Hebt u een digitale meter:</Typography> */}
                            {/* <Select
                  value={hasDigitalMeter}
                  onChange={handleDigitalMeterChange}
                  style={{ minWidth: "120px" }}
                >
                  <MenuItem value="Ja">Ja</MenuItem>
                  <MenuItem value="Nee">Nee</MenuItem>
                </Select> */}
                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  id="typemeter"
                                  select
                                  label="Digitale Meter"
                                  value={hasDigitalMeter}
                                  onChange={handleDigitalMeterChange}
                                  variant="standard"
                                  fullWidth
                                >
                                  <MenuItem value="Ja">Ja</MenuItem>
                                  <MenuItem value="Nee">Nee</MenuItem>
                                </TextField>
                              </Box>
                            </div>

                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  id="typemeter"
                                  select
                                  label="Hebt u zonnepanelen?"
                                  value={hasSolarPanels}
                                  onChange={handleSolarPanelsChange}
                                  variant="standard"
                                  fullWidth
                                  required
                                >
                                  <MenuItem value="JA">Ja</MenuItem>
                                  <MenuItem value="NEE">Nee</MenuItem>
                                </TextField>
                              </Box>
                            </div>

                            {isChecked1Alt && (
                              <div className="form-group">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <TextField
                                    label="Jaarlijks enkelvoudig verbruik (KwH)"
                                    variant="standard"
                                    type="number"
                                    id="verbruikvalue"
                                    value={verbruikValue}
                                    onChange={handleVerbruikChange}
                                    fullWidth
                                    required
                                  />
                                </Box>
                              </div>

                              // <div>
                              //   <Typography variant="h6">Verbruik:</Typography>
                              //   <input
                              //     type="number"
                              //     value={verbruikValue}
                              //     onChange={handleVerbruikChange}
                              //   />
                              // </div>
                            )}

                            {/* <Typography variant="h6">Netto afname:</Typography>
                <input
                  type="number"
                  value={nettoAfname}
                  onChange={handleNettoAfnameChange}
                /> */}
                            {isChecked2Alt && (
                              <div>
                                <div className="form-group">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <TextField
                                      label="Jaarlijks verbruik dag"
                                      variant="standard"
                                      type="number"
                                      id="nettoafnamedagvalue"
                                      value={nettoAfnameDag}
                                      onChange={handleNettoAfnameDagChange}
                                      fullWidth
                                    />
                                  </Box>
                                </div>
                                <div className="form-group">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <TextField
                                      label="Jaarlijks verbruik nacht"
                                      variant="standard"
                                      type="number"
                                      id="nettoafnamenachtweekendvalue"
                                      value={nettoAfnameWeekend}
                                      onChange={handleNettoAfnameWeekendChange}
                                      fullWidth
                                    />
                                  </Box>
                                </div>
                              </div>
                              // <div>
                              //   <Typography variant="h6">Jaarlijks enkelvoudig verbruik (KwH):</Typography>
                              //   <input
                              //     type="number"
                              //     value={nettoAfnameDag}
                              //     onChange={handleNettoAfnameDagChange}
                              //   />

                              //   <Typography variant="h6">
                              //     Jaarlijks verbruik nacht:
                              //   </Typography>
                              //   <input
                              //     type="number"
                              //     value={nettoAfnameWeekend}
                              //     onChange={handleNettoAfnameWeekendChange}
                              //   />
                              // </div>
                            )}
                            {isChecked3Alt && (
                              <div>
                                <div className="form-group">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <TextField
                                      label="Jaarlijks enkelvoudig verbruik (KwH)"
                                      variant="standard"
                                      type="number"
                                      id="verbruikvalue"
                                      value={verbruikValue}
                                      onChange={handleVerbruikChange}
                                      fullWidth
                                    />
                                  </Box>
                                </div>

                                <div className="form-group">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <TextField
                                      label="Jaarlijks verbruik exclusief nacht"
                                      variant="standard"
                                      type="number"
                                      id="nettoafnameuitsluitendnacht"
                                      value={nettoAfnameUitNacht}
                                      onChange={handleNettoAfnameUitNachtChange}
                                      fullWidth
                                    />
                                  </Box>
                                </div>
                                {/* <Typography variant="h6">
                      Jaarlijks verbruik exclusief nacht:
                    </Typography>
                    <input
                      type="number"
                      value={nettoAfnameUitNacht}
                      onChange={handleNettoAfnameUitNachtChange}
                    /> */}
                              </div>
                            )}

                            {isChecked4Alt && (
                              <div>
                                <div className="form-group">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <TextField
                                      label="Jaarlijks enkelvoudig verbruik (KwH)"
                                      variant="standard"
                                      type="number"
                                      id="nettoafnamedag"
                                      value={nettoAfnameDag}
                                      onChange={handleNettoAfnameDagChange}
                                      fullWidth
                                    />
                                  </Box>
                                </div>

                                {/* <Typography variant="h6">Jaarlijks enkelvoudig verbruik (KwH):</Typography>
                    <input
                      type="number"
                      value={nettoAfnameDag}
                      onChange={handleNettoAfnameDagChange}
                    /> */}

                                <div className="form-group">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <TextField
                                      label=" Jaarlijks verbruik nacht"
                                      variant="standard"
                                      type="number"
                                      id="nettoafnameweekend"
                                      value={nettoAfnameWeekend}
                                      onChange={handleNettoAfnameWeekendChange}
                                    />
                                  </Box>
                                </div>
                                <div className="form-group">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <TextField
                                      label="Jaarlijks verbruik exclusief nacht"
                                      variant="standard"
                                      type="number"
                                      id="nettoafnameuitsluitendnacht"
                                      value={nettoAfnameUitNacht}
                                      onChange={handleNettoAfnameUitNachtChange}
                                      fullWidth
                                    />
                                  </Box>
                                </div>

                                {/* <Typography variant="h6">
                      Jaarlijks verbruik nacht:
                    </Typography>
                    <input
                      type="number"
                      value={nettoAfnameWeekend}
                      onChange={handleNettoAfnameWeekendChange}
                    /> */}
                                {/* <Typography variant="h6">
                      Jaarlijks verbruik exclusief nacht:
                    </Typography>
                    <input
                      type="number"
                      value={nettoAfnameUitNacht}
                      onChange={handleNettoAfnameUitNachtChange}
                    />*/}
                              </div>
                            )}

                            {hasSolarPanels === "JA" && (
                              <div>
                                <div className="form-group">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <TextField
                                      label="Wat is het totaal vermogen van de omvormer(s) van je
                          installatie?"
                                      variant="standard"
                                      type="number"
                                      id="vermogenomvormerinstallatie"
                                      value={omvormersVermogen}
                                      onChange={handleOmvormersVermogenchange}
                                      fullWidth
                                    />
                                  </Box>
                                </div>
                                {/* <Typography variant="h6">
            Wat is het totaal vermogen van de omvormer(s) van je
            installatie? :
          </Typography>
          <input
            type="number"
            value={omvormersVermogen}
            onChange={handleOmvormersVermogenchange}
          /> */}
                              </div>
                            )}
                          </div>
                        )}

                        {activeStep === 2 && isChecked2 && (
                          <div>
                            <Typography
                              variant="h6"
                              style={{ marginTop: "50px" }}
                            >
                              Type Woning:
                            </Typography>
                            <div
                              className="squareContainer"
                              style={squareContainerStyles}
                            >
                              {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}> */}
                              <div
                                className="square"
                                style={{
                                  ...squareStyles,
                                  border:
                                    selectedWoning === "appartement"
                                      ? "2px solid #8dc63f"
                                      : "none",
                                }}
                                onClick={() => setSelectedWoning("appartement")}
                              >
                                <ApartmentIcon
                                  style={{ fontSize: "50px", color: "#8dc63f" }}
                                />
                                <h4>Appartement</h4>
                              </div>
                              <div
                                className="square"
                                style={{
                                  ...squareStyles,
                                  border:
                                    selectedWoning === "tussenwoning"
                                      ? "2px solid #8dc63f"
                                      : "none",
                                }}
                                onClick={() =>
                                  setSelectedWoning("tussenwoning")
                                }
                              >
                                <HomeIcon
                                  style={{ fontSize: "50px", color: "#8dc63f" }}
                                />
                                <h4>Tussenwoning</h4>
                              </div>
                              <div
                                className="square"
                                style={{
                                  ...squareStyles,
                                  border:
                                    selectedWoning === "hoekwoning"
                                      ? "2px solid #8dc63f"
                                      : "none",
                                }}
                                onClick={() => setSelectedWoning("hoekwoning")}
                              >
                                <WeekendIcon
                                  style={{ fontSize: "50px", color: "#8dc63f" }}
                                />
                                <h4>Hoekwoning</h4>
                              </div>
                              <div
                                className="square"
                                style={{
                                  ...squareStyles,
                                  border:
                                    selectedWoning === "vrijstaandewoning"
                                      ? "2px solid #8dc63f"
                                      : "none",
                                }}
                                onClick={() =>
                                  setSelectedWoning("vrijstaandewoning")
                                }
                              >
                                <HouseIcon
                                  style={{ fontSize: "50px", color: "#8dc63f" }}
                                />
                                <h4>Vrijstaande woning</h4>
                              </div>
                            </div>

                            <Typography
                              variant="h6"
                              style={{ marginTop: "50px" }}
                            >
                              Aantal bewoners:
                            </Typography>
                            <div
                              className="squareContainer"
                              style={squareContainerStyles}
                            >
                              <div
                                className="square"
                                style={{
                                  ...squareStyles,
                                  border:
                                    selectedResidents === "onePerson"
                                      ? "2px solid #8dc63f"
                                      : "none",
                                }}
                                onClick={() => {
                                  setSelectedResidents("onePerson");
                                  if (selectedWoning === "appartement") {
                                    setVerbruikValue("1800");
                                  }
                                  if (selectedWoning === "tussenwoning") {
                                    setVerbruikValue("1900");
                                  }
                                  if (selectedWoning === "hoekwoning") {
                                    setVerbruikValue("2000");
                                  }
                                  if (selectedWoning === "vrijstaandewoning") {
                                    setVerbruikValue("2600");
                                  }
                                }}
                              >
                                <PersonIcon
                                  style={{ fontSize: "50px", color: "#8dc63f" }}
                                />
                                <h4>1 persoon</h4>
                              </div>
                              <div
                                className="square"
                                style={{
                                  ...squareStyles,
                                  border:
                                    selectedResidents === "twoPersons"
                                      ? "2px solid #8dc63f"
                                      : "none",
                                }}
                                onClick={() => {
                                  setSelectedResidents("twoPersons");
                                  if (selectedWoning === "appartement") {
                                    setVerbruikValue("2200");
                                  }
                                  if (selectedWoning === "tussenwoning") {
                                    setVerbruikValue("2400");
                                  }
                                  if (selectedWoning === "hoekwoning") {
                                    setVerbruikValue("2600");
                                  }
                                  if (selectedWoning === "vrijstaandewoning") {
                                    setVerbruikValue("3200");
                                  }
                                }}
                              >
                                <GroupIcon
                                  style={{ fontSize: "50px", color: "#8dc63f" }}
                                />
                                <h4>2 personen</h4>
                              </div>
                              <div
                                className="square"
                                style={{
                                  ...squareStyles,
                                  border:
                                    selectedResidents === "threeFourPersons"
                                      ? "2px solid #8dc63f"
                                      : "none",
                                }}
                                onClick={() => {
                                  setSelectedResidents("threeFourPersons");
                                  if (selectedWoning === "appartement") {
                                    setVerbruikValue("2700");
                                  }
                                  if (selectedWoning === "tussenwoning") {
                                    setVerbruikValue("3000");
                                  }
                                  if (selectedWoning === "hoekwoning") {
                                    setVerbruikValue("3200");
                                  }
                                  if (selectedWoning === "vrijstaandewoning") {
                                    setVerbruikValue("3800");
                                  }
                                }}
                              >
                                <GroupsIcon
                                  style={{ fontSize: "50px", color: "#8dc63f" }}
                                />
                                <h4>3 - 4 personen</h4>
                              </div>
                              <div
                                className="square"
                                style={{
                                  ...squareStyles,
                                  border:
                                    selectedResidents === "fiveSixPersons"
                                      ? "2px solid #8dc63f"
                                      : "none",
                                }}
                                onClick={() => {
                                  setSelectedResidents("fiveSixPersons");
                                  if (selectedWoning === "appartement") {
                                    setVerbruikValue("2800");
                                  }
                                  if (selectedWoning === "tussenwoning") {
                                    setVerbruikValue("4000");
                                  }
                                  if (selectedWoning === "hoekwoning") {
                                    setVerbruikValue("4100");
                                  }
                                  if (selectedWoning === "vrijstaandewoning") {
                                    setVerbruikValue("4800");
                                  }
                                }}
                              >
                                <GroupsIcon
                                  style={{ fontSize: "50px", color: "#8dc63f" }}
                                />
                                <h4>5 - 6 personen</h4>
                              </div>
                            </div>

                            {/* <Typography variant="h6">Type meter:</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked1Alt}
                      onChange={handleCheckbox1AltChange}
                      name="checkbox1Alt"
                    />
                  }
                  label="Dag (enkelvoudig tarief)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked2Alt}
                      onChange={handleCheckbox2AltChange}
                      name="checkbox2Alt"
                    />
                  }
                  label="Dag/nacht (tweevoudig tarief)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked3Alt}
                      onChange={handleCheckbox3AltChange}
                      name="checkbox3Alt"
                    />
                  }
                  label="Dag (enkelvoudig tarief) + uitsluitend nacht"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked4Alt}
                      onChange={handleCheckbox4AltChange}
                      name="checkbox4Alt"
                    />
                  }
                  label="Dag/nacht (tweevoudig tarief) + uitsluitend nacht"
                /> */}

                            {/* <div className="form-group">
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <TextField
                    id="typemeter"
                    select
                    label="Type meter"
                   
                    onChange={(event) => handleAltChange(event)}
                    variant="standard"
                  >


        <MenuItem value={'isChecked1Alt'}>Dag (enkelvoudig tarief)</MenuItem>
        <MenuItem value={'isChecked2Alt'}>Dag/nacht (tweevoudig tarief)</MenuItem>
        <MenuItem value={'isChecked3Alt'}>Dag (enkelvoudig tarief) + uitsluitend nacht</MenuItem>
        <MenuItem value={'isChecked4Alt'}>Dag/nacht (tweevoudig tarief) + uitsluitend nacht</MenuItem>
      
                  </TextField>
                </Box>
              </div> */}

                            {/* <Typography variant="h6">Hebt u een digitale meter:</Typography>
                <Select
                  value={hasDigitalMeter}
                  onChange={handleDigitalMeterChange}
                  style={{ minWidth: "120px" }}
                >
                  <MenuItem value="Ja">Ja</MenuItem>
                  <MenuItem value="Nee">Nee</MenuItem>
                </Select>

                <Typography variant="h6">Hebt u Zonnepanelen:</Typography>
                <Select
                  value={hasSolarPanels}
                  onChange={handleSolarPanelsChange}
                  style={{ minWidth: "120px" }}
                >
                  <MenuItem value="JA">Ja</MenuItem>
                  <MenuItem value="NEE">Nee</MenuItem>
                </Select> */}

                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  id="typemeter"
                                  select
                                  label="Digitale Meter"
                                  value={hasDigitalMeter}
                                  onChange={handleDigitalMeterChange}
                                  variant="standard"
                                  fullWidth
                                >
                                  <MenuItem value="Ja">Ja</MenuItem>
                                  <MenuItem value="Nee">Nee</MenuItem>
                                </TextField>
                              </Box>
                            </div>

                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  id="typemeter"
                                  select
                                  label="Hebt u zonnepanelen?"
                                  value={hasSolarPanels}
                                  onChange={handleSolarPanelsChange}
                                  variant="standard"
                                  fullWidth
                                >
                                  <MenuItem value="JA">Ja</MenuItem>
                                  <MenuItem value="NEE">Nee</MenuItem>
                                </TextField>
                              </Box>
                            </div>

                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  label="Jaarlijks enkelvoudig verbruik (KwH)"
                                  variant="standard"
                                  type="number"
                                  id="verbruikvalue"
                                  value={verbruikValue}
                                  onChange={handleVerbruikChange}
                                  fullWidth
                                />
                              </Box>
                            </div>

                            {/* <Typography variant="h6">Netto afname:</Typography>
                <input
                  type="number"
                  value={nettoAfname}
                  onChange={handleNettoAfnameChange}
                /> */}
                            {/* {isChecked2Alt && (
                  // <div>
                  //   <Typography variant="h6">Jaarlijks enkelvoudig verbruik (KwH):</Typography>
                  //   <input
                  //     type="number"
                  //     value={nettoAfnameDag}
                  //     onChange={handleNettoAfnameDagChange}
                  //   />

                  //   <Typography variant="h6">
                  //     Jaarlijks verbruik nacht:
                  //   </Typography>
                  //   <input
                  //     type="number"
                  //     value={nettoAfnameWeekend}
                  //     onChange={handleNettoAfnameWeekendChange}
                  //   />
                  // </div>
                  <div>
                  <div className="form-group">
                   <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                   
                     <TextField
                       label="Jaarlijks enkelvoudig verbruik (KwH)"
                       variant="standard"
                       type="number"
                       id="nettoafnamedagvalue"
                       value={nettoAfnameDag}
                       onChange={handleNettoAfnameDagChange}
                     />
                   </Box>
                   </div>
                    <div className="form-group">
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    
                      <TextField
                        label="Jaarlijks verbruik nacht"
                        variant="standard"
                        type="number"
                        id="nettoafnamenachtweekendvalue"
                        value={nettoAfnameWeekend}
                        onChange={handleNettoAfnameWeekendChange}
                      />
                    </Box>
                    </div>
                    </div>
                )}
                {isChecked3Alt && (
                  // <div>
                  //   <Typography variant="h6">Verbruik:</Typography>
                  //   <input
                  //     type="number"
                  //     value={verbruikValue}
                  //     onChange={handleVerbruikChange}
                  //   />

                  //   <Typography variant="h6">
                  //     Jaarlijks verbruik exclusief nacht:
                  //   </Typography>
                  //   <input
                  //     type="number"
                  //     value={nettoAfnameUitNacht}
                  //     onChange={handleNettoAfnameUitNachtChange}
                  //   />
                  // </div>
                  <div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      
                        <TextField
                          label="Jaarlijks verbruik dag"
                          variant="standard"
                          type="number"
                          id="verbruikvalue"
                          value={verbruikValue}
                          onChange={handleVerbruikChange}
                        />
                      </Box>
                      </div>

                      <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      
                        <TextField
                          label="Jaarlijks verbruik exclusief nacht"
                          variant="standard"
                          type="number"
                          id="nettoafnameuitsluitendnacht"
                          value={nettoAfnameUitNacht}
                          onChange={handleNettoAfnameUitNachtChange}
                        />
                      </Box>
                      </div>
                      </div>
                )}

                {isChecked4Alt && (
                  // <div>
                  //   <Typography variant="h6">Jaarlijks enkelvoudig verbruik (KwH):</Typography>
                  //   <input
                  //     type="number"
                  //     value={nettoAfnameDag}
                  //     onChange={handleNettoAfnameDagChange}
                  //   />

                  //   <Typography variant="h6">
                  //     Jaarlijks verbruik nacht:
                  //   </Typography>
                  //   <input
                  //     type="number"
                  //     value={nettoAfnameWeekend}
                  //     onChange={handleNettoAfnameWeekendChange}
                  //   />
                  //   <Typography variant="h6">
                  //     Jaarlijks verbruik exclusief nacht:
                  //   </Typography>
                  //   <input
                  //     type="number"
                  //     value={nettoAfnameUitNacht}
                  //     onChange={handleNettoAfnameUitNachtChange}
                  //   />
                  // </div>
                  <div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      
                        <TextField
                          label="Jaarlijks enkelvoudig verbruik (KwH)"
                          variant="standard"
                          type="number"
                          id="nettoafnamedag"
                          value={nettoAfnameDag}
                          onChange={handleNettoAfnameDagChange}
                        />
                      </Box>
                      </div>

          

                      <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      
                        <TextField
                          label=" Jaarlijks verbruik nacht"
                          variant="standard"
                          type="number"
                          id="nettoafnameweekend"
                          value={nettoAfnameWeekend}
                          onChange={handleNettoAfnameWeekendChange}
                        />
                      </Box>
                      </div>
                      <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      
                        <TextField
                          label="Jaarlijks verbruik exclusief nacht"
                          variant="standard"
                          type="number"
                          id="nettoafnameuitsluitendnacht"
                          value={nettoAfnameUitNacht}
                          onChange={handleNettoAfnameUitNachtChange}
                        />
                      </Box>
                      </div>
                      
          
                  </div> 
                )} */}

                            {hasSolarPanels === "JA" && (
                              //             <div>
                              //             <Typography variant="h6">
                              //             Wat is het totaal vermogen van de omvormer(s) van je
                              //             installatie? :
                              //           </Typography>
                              //           <input
                              //             type="number"
                              //             value={omvormersVermogen}
                              //             onChange={handleOmvormersVermogenchange}
                              //           />
                              // </div>
                              <div>
                                <div className="form-group">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <Tooltip
                                      title="Deze info vind u :op de sticker aan de zijkant van uw omvormer(s), op uw factuur/offerte, op uw keuringsverslag."
                                      placement="top"
                                    >
                                      <InfoIcon
                                        sx={{
                                          color: "action.active",
                                          mr: 1,
                                          my: 0.5,
                                        }}
                                      />
                                    </Tooltip>
                                    <TextField
                                      label="(Totaal) vermogen omvormers (KVA)"
                                      variant="standard"
                                      type="number"
                                      id="vermogenomvormerinstallatie"
                                      fullWidth
                                      value={omvormersVermogen}
                                      onChange={handleOmvormersVermogenchange}
                                    />
                                  </Box>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {activeStep === 3 && (
                          <div>
                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  id="Birthdatedd"
                                  select
                                  label="Huidige energieleverancier"
                                  fullWidth
                                  value={supplierId}
                                  onChange={handleSupplierIdChange}
                                  variant="standard"
                                >
                                  {/* {energySuppliers.map((supplier) => (
                    <MenuItem key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </MenuItem>
                  ))} */}
                                  {[...energySuppliers]
                                    .sort((a, b) =>
                                      a.name.localeCompare(b.name),
                                    )
                                    .map((supplier) => (
                                      <MenuItem
                                        key={supplier.id}
                                        value={supplier.id}
                                      >
                                        {supplier.name}
                                      </MenuItem>
                                    ))}
                                </TextField>
                              </Box>
                            </div>

                            {/* <Typography variant="h6">
                  Huidige energieleverancier:
                </Typography>
                <Select
                  value={supplierId} // Use the correct state for the selected value
                  onChange={handleSupplierIdChange} // Use the correct change handler
                  style={{ minWidth: "120px" }}
                >
                  <MenuItem value="">Selecteer een energieleverancier</MenuItem>
                  {energySuppliers.map((supplier) => (
                    <MenuItem key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </MenuItem>
                  ))}
                </Select> */}

                            <div className="form-group">
                              <Box
                                sx={{ display: "flex", alignItems: "flex-end" }}
                              >
                                <TextField
                                  id="typecontract"
                                  select
                                  label="Gewenste contract type"
                                  // value={birthdatedd}
                                  onChange={(event) => handleCtChange(event)}
                                  variant="standard"
                                  required
                                  fullWidth
                                >
                                  <MenuItem value={"isCheckedCt1"}>
                                    Alle (contracten met “vaste” energieprijs +
                                    contracten met “variabele” energieprijs)
                                  </MenuItem>
                                  <MenuItem value={"isCheckedCt2"}>
                                    Contracten met een “vaste” energieprijs
                                  </MenuItem>
                                  <MenuItem value={"isCheckedCt3"}>
                                    Contracten met een “variabele” energieprijs
                                  </MenuItem>
                                </TextField>
                              </Box>
                            </div>
                            {/* <Typography variant="h6">Gewenste prijstype:</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCheckedCt1}
                      onChange={handleCheckboxCt1Change}
                      name="checkboxCt1"
                    />
                  }
                  label="Alle (contracten met “vaste” energieprijs + contracten met “variabele” energieprijs)"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCheckedCt2}
                      onChange={handleCheckbox2CtChange}
                      name="checkboxCt2"
                    />
                  }
                  label="Contracten met een “vaste” energieprijs"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCheckedCt3}
                      onChange={handleCheckboxCt3Change}
                      name="checkboxCt3"
                    />
                  }
                  label="Contracten met een “variabele” energieprijs"
                /> */}
                          </div>
                        )}

                        {/* Step navigation buttons */}
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                          >
                            Ga terug
                          </Button>
                          {/* {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Sla over
                </Button>
              )} */}
                          <Button onClick={handleNext}>
                            {activeStep === steps.length - 1
                              ? "Bereken mijn prijzen"
                              : "Volgende stap"}
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
                <div className="textColumnsolar">
                  <SquareComponent />
                  <SquareComponentIt />
                </div>
              </div>
            </div>

            <Footer />
          </div>
        ) : (
          <div> </div>
        )}
      </>
    </ThemeProvider>
  );
};

export default ElectricityContractInput;
