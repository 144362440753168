import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import Footer from "../../../components/Footer/Footer";
import PageHero from "../../../components/Pagehero/PageHero";
import "./faq.css";
import "./../../../theme/styles.css";
import { getCookie } from "../../../cookieUtils";
import CenterTitle from "../../../components/CenterTitle/CenterTitle";

interface FaqItem {
  question: string;
  answer: string;
}

const faqData: FaqItem[] = [
  { question: "What is your return policy?", answer: "Our return policy lasts 30 days..." },
  { question: "How do I track my order?", answer: "You can track your order using the tracking link sent to your email..." },
  { question: "Can I purchase a gift card?", answer: "Yes, we offer gift cards for different amounts..." },
  // Add more FAQ items as needed
];

const Faq: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [expanded, setExpanded] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const toggleAnswer = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Veelgestelde vragen</PageHero>
          <div className="faq-container">
            <NavigationCustomer isMenuVisible={isMenuVisible} toggleMenu={handleToggleMenu} />
            
            <CenterTitle
                title="Veelgestelde vragen"
                subtitle="Hier vindt u de antwoorden op de meest gestelde vragen. We hopen dat deze informatie u helpt, 
                maar neem gerust contact met ons op als u verdere hulp nodig heeft."
              ></CenterTitle>
            
            
            <div className="faq-grid">
              {faqData.map((item, index) => (
                <div key={index} className="faq-item">
                  <div className="faq-question" onClick={() => toggleAnswer(index)}>
                    {item.question}
                  </div>
                  {expanded === index && <div className="faq-answer">{item.answer}</div>}
                </div>
              ))}
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Faq;

