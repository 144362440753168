import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import "../Articles.css";
import ThermostatuseImage from "./thermostatuse.jpg";
import { getCookie } from "../../../../../cookieUtils";

const ThermostatUseArticle = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div className="full-page-background">
          <NavBar toggleMenu={handleToggleMenu}  />
          <PageHeroColored>
            Hoe verbruik ik minder door de thermostaat een graadje lager in de winter te zetten, en een graadje hoger in de zomer?
          </PageHeroColored>

          <div className="page-container">
            <NavigationCustomer isMenuVisible={isMenuVisible} toggleMenu={handleToggleMenu} />

            <div className="content-container">
              {/* Featured Image */}
              <div className="featured-image">
                <img src={ThermostatuseImage} alt="Thermostat Use" />
              </div>

              {/* Blog Content */}
              <div className="blog-content">
                <p>
                  <b>
                    De thermostaat is een handig hulpmiddel om de temperatuur in je huis te regelen. Door slim gebruik te maken van de thermostaat, kun je energie besparen en je energierekening verlagen. Een eenvoudige manier om dit te doen, is door de thermostaat een graadje lager in de winter te zetten en een graadje hoger in de zomer.
                  </b>
                </p>

                <h2>Winter: Thermostaat een graadje lager</h2>
                <p>
                  In de winter kun je energie besparen door de thermostaat een graadje lager te zetten. Een comfortabele temperatuur ligt meestal tussen de 18°C en 20°C. Door een trui te dragen en een warme deken te gebruiken, kun je het nog steeds aangenaam hebben zonder de thermostaat hoger te zetten.
                </p>

                <h2>Zomer: Thermostaat een graadje hoger</h2>
                <p>
                  In de zomer kun je op dezelfde manier energie besparen door de thermostaat een graadje hoger te zetten. Probeer de temperatuur rond de 25°C te houden, zodat het comfortabel blijft zonder te veel energie te verbruiken.
                </p>

                <h2>Conclusie</h2>
                <p>
                  Door bewust om te gaan met de thermostaat en deze een graadje lager in de winter en een graadje hoger in de zomer te zetten, kun je besparen op je energieverbruik. Dit helpt niet alleen je energierekening, maar ook het milieu.
                </p>
                <p>
                  Probeer daarnaast ook andere energiebesparende maatregelen, zoals het goed isoleren van je huis en het gebruik van energiezuinige verlichting. Samen kunnen we bijdragen aan een groenere toekomst en onze eigen kosten verlagen.
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div>Access Restricted</div>
      )}
    </>
  );
};

export default ThermostatUseArticle;
