import React, { useEffect, useState } from "react";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import NavBar from "../../../../components/Navigation/NavBar";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MenuItem } from "@mui/material";
import axios from "axios";
import PageHero from "../../../../components/Pagehero/PageHero";
import * as XLSX from "xlsx";
import { getCookie } from "../../../../cookieUtils";

interface Contract {
  id: string;
  supplierId: string;
  name: string;
  description: string;
  category: number;
}

interface ChangeContractRequest {
  id: string;
  contractIdElectricity: string;
  contractIdGas: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  status: number;
  verhuis: number;
  nutsvoorzieningen: number;
  datetimeCreated: Date;
}

const ChangeEnergyContract: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [changeContractRequests, setChangeContractRequests] = useState<
    ChangeContractRequest[]
  >([]);
  const [surnameFilter, setSurnameFilter] = useState("");
  const [nameFilter, setnameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [roleId, setRoleId] = useState<number | null>(null);
  const [statusFilter, setStatusFilter] = useState("All");
  const [sortOrder, setSortOrder] = useState<"newest" | "oldest">("newest");
  const navigate = useNavigate();

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    // Fetch Contracts
    axios
      .get(`${process.env.REACT_APP_DB_URL}/contracts`)
      .then((response) => {
        setContracts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch Change Energy Contract Requests
    axios
      .get(`${process.env.REACT_APP_DB_URL}/ChangeContractRequests`)
      .then((response) => {
        setChangeContractRequests(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const navigationmenutop: React.CSSProperties = {
    backgroundColor: "#ffffff",
    padding: "20px",
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#272727",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleChangeEnergyContractDetail = (requestid: string) => {
    // Navigate to application details page
    const id = requestid.toString();
    navigate(`/ChangeEnergyContractDetail/${id}`);
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const exportToExcel = () => {
    const dataToExport = filteredChangeContractRequests.map((request) => ({
      Voornaam: request.surname,
      Naam: request.name,
      Email: request.email,
      Telefoonnummer: request.phone,
      Elektriciteitscontract: request.contractIdElectricity
        ? contracts.find(
            (contract) => contract.id === request.contractIdElectricity,
          )?.name || "Niet van toepassing"
        : "Niet van toepassing",
      GasContract: request.contractIdGas
        ? contracts.find((contract) => contract.id === request.contractIdGas)
            ?.name || "Niet van toepassing"
        : "Niet van toepassing",
      "Datum aanvraag": request.datetimeCreated.toLocaleString(),
      Status:
        request.status === 1
          ? "Openstaand"
          : request.status === 2
            ? "Bewerkt"
            : request.status === 3
              ? "Geaccepteerd"
              : request.status === 4
                ? "Afgewezen"
                : "Niet Actief",
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ChangeEnergyContract");

    XLSX.writeFile(wb, "ChangeEnergyContract.xlsx");
  };

  const filteredChangeContractRequests = changeContractRequests.filter(
    (request: ChangeContractRequest) => {
      const contract = contracts.find(
        (contract) =>
          contract.id === request.contractIdElectricity ||
          contract.id === request.contractIdGas,
      );
      const surnameMatch = request.surname
        .toLowerCase()
        .includes(surnameFilter.toLowerCase());
      const nameMatch = request.name
        .toLowerCase()
        .includes(nameFilter.toLowerCase());
      const emailMatch = request.email
        .toLowerCase()
        .includes(emailFilter.toLowerCase());

      // Check if the status matches the selected filter or if the filter is set to "All"
      const statusMatch =
        statusFilter === "All" || request.status.toString() === statusFilter;

      return surnameMatch && nameMatch && emailMatch && statusMatch;
    },
  );

  const handleSortByDate = () => {
    setSortOrder((prevSortOrder) =>
      prevSortOrder === "newest" ? "oldest" : "newest",
    );
  };

  const sortedSolarPanelRequests = filteredChangeContractRequests.sort(
    (a, b) => {
      const dateA = new Date(a.datetimeCreated);
      const dateB = new Date(b.datetimeCreated);

      if (sortOrder === "newest") {
        return dateB.getTime() - dateA.getTime();
      } else {
        return dateA.getTime() - dateB.getTime();
      }
    },
  );

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Verander energiecontract</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="filterRow">
                <div className="filterContainer">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <AccountCircle
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                    <TextField
                      label="Filter op Voornaam: "
                      variant="standard"
                      type="text"
                      id="surnameFilter"
                      value={surnameFilter}
                      onChange={(e) => setSurnameFilter(e.target.value)}
                    />
                  </Box>
                </div>
                <div className="filterContainer">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <AccountCircle
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                    <TextField
                      label="Filter op Naam: "
                      variant="standard"
                      type="text"
                      id="nameFilter"
                      value={nameFilter}
                      onChange={(e) => setnameFilter(e.target.value)}
                    />
                  </Box>
                </div>
                <div className="filterContainer">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <AlternateEmailIcon
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                    <TextField
                      label="Filter op Email: "
                      variant="standard"
                      type="text"
                      id="emailFilter"
                      value={emailFilter}
                      onChange={(e) => setEmailFilter(e.target.value)}
                    />
                  </Box>
                </div>

                <div className="filterContainer">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <TextField
                      select
                      label="Filter op Status:"
                      variant="standard"
                      value={statusFilter}
                      onChange={(e) => setStatusFilter(e.target.value)}
                    >
                      <MenuItem key="All" value="All">
                        Alle Statussen
                      </MenuItem>
                      <MenuItem key="1" value="1">
                        Openstaand
                      </MenuItem>
                      <MenuItem key="2" value="2">
                        Bewerkt
                      </MenuItem>
                      <MenuItem key="3" value="3">
                        Geaccepteerd
                      </MenuItem>
                      <MenuItem key="4" value="4">
                        Afgewezen
                      </MenuItem>
                    </TextField>
                  </Box>
                </div>
                <button className="buttonstyleR33" onClick={exportToExcel}>
                  {/* <IoIosDownload size={20} /> */}
                  Exporteer naar Excel
                </button>
              </div>
              <hr className="dividerDashed" />
              <div className="recordCount">
                <p>{` ${filteredChangeContractRequests.length} van de ${changeContractRequests.length} records zichtbaar`}</p>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Voornaam</StyledTableCell>
                      <StyledTableCell align="right">Naam</StyledTableCell>
                      <StyledTableCell align="right">Email</StyledTableCell>
                      <StyledTableCell align="right">
                        Telefoonnummer
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Elektriciteitscontract
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Gas contract
                      </StyledTableCell>
                      <StyledTableCell align="right">Verhuis?</StyledTableCell>
                      <StyledTableCell align="right">Nutsvoorzieningen?</StyledTableCell>
                      <StyledTableCell align="right" onClick={handleSortByDate}>
                        Datum aanvraag
                        {sortOrder === "newest" ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropUpIcon />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="right">Status</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredChangeContractRequests.map((request) => {
                      const electricityContract = contracts.find(
                        (contract) =>
                          contract.id === request.contractIdElectricity,
                      );
                      const gasContract = contracts.find(
                        (contract) => contract.id === request.contractIdGas,
                      );

                      return (
                        <StyledTableRow key={request.id}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            onClick={() =>
                              handleChangeEnergyContractDetail(request.id)
                            }
                          >
                            {request.surname}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              handleChangeEnergyContractDetail(request.id)
                            }
                            align="right"
                          >
                            {request.name}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              handleChangeEnergyContractDetail(request.id)
                            }
                            align="right"
                          >
                            {request.email}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              handleChangeEnergyContractDetail(request.id)
                            }
                            align="right"
                          >
                            {request.phone}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              handleChangeEnergyContractDetail(request.id)
                            }
                            align="right"
                          >
                            {electricityContract
                              ? electricityContract.name
                              : "Niet van toepassing"}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              handleChangeEnergyContractDetail(request.id)
                            }
                            align="right"
                          >
                            {gasContract
                              ? gasContract.name
                              : "Niet van toepassing"}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              handleChangeEnergyContractDetail(request.id)
                            }
                            align="right"
                          >
                            {request.verhuis === 0
                              ? "Nee"
                              : request.verhuis === 1
                                ? "Ja"
                                : "Nee"}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              handleChangeEnergyContractDetail(request.id)
                            }
                            align="right"
                          >
                            {request.nutsvoorzieningen === 0
                              ? "Nee"
                              : request.nutsvoorzieningen === 1
                                ? "Ja"
                                : "Nee"}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              handleChangeEnergyContractDetail(request.id)
                            }
                            align="right"
                          >
                            {request.datetimeCreated.toLocaleString()}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() =>
                              handleChangeEnergyContractDetail(request.id)
                            }
                            align="right"
                          >
                            {request.status === 1
                              ? "Openstaand"
                              : request.status === 2
                                ? "Bewerkt"
                                : request.status === 3
                                  ? "Geaccepteerd"
                                  : request.status === 4
                                    ? "Afgewezen"
                                    : "Niet Actief"}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default ChangeEnergyContract;
