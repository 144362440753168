import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoGrid, IoChatbubbles, IoReceipt, IoClose } from "react-icons/io5";
import { FaHandsHelping } from "react-icons/fa";
import { PiHandCoins } from "react-icons/pi";
import { setCookie, getCookie, eraseCookie } from "../../cookieUtils";

interface NavigationCustomerProps {
  isMenuVisible: boolean;
  toggleMenu: () => void;
}

interface UserAddress {
  id: string;
  name: string;
  userId: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  dateTimeCreated: Date;
}

const NavigationCustomer: React.FC<NavigationCustomerProps> = ({
  isMenuVisible,
  toggleMenu,
}) => {
  useEffect(() => {
    console.log("isMenuVisible:", isMenuVisible);
    document.body.classList.toggle("no-scroll", isMenuVisible);

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isMenuVisible]);

  const navigationStyles: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: isMenuVisible ? 0 : "-120%", // Hide the navigation by default
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.99)", // Black background with transparency
    display: "flex",
    justifyContent: "center", // Center items horizontally
    alignItems: "center", // Center items vertically
    zIndex: 1000, // Adjust z-index as needed
    transition: "all 0.3s",
  };

  const menuItemStyles: React.CSSProperties = {
    marginBottom: "20px", // Add spacing between menu items
    padding: "10px",
    cursor: "pointer",
    transition: "color 0.3s",
    alignItems: "center",
    color: "white", // Set text color to white
  };

  const closeIconStyles: React.CSSProperties = {
    position: "fixed",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    zIndex: 1001, // Make sure it's above the navigation
    color: "white", // Set the icon color to white
  };

  const [userAddresses, setUserAddresses] = useState<UserAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string | null>(null);
  const navigate = useNavigate();

  const closeMenu = () => {
    toggleMenu(); // Close the menu
  };

  const navigateAndCloseMenu = (path: string) => {
    navigate(path);
    closeMenu(); // Close the menu after navigation
  };

  const logoutAndCloseMenu = () => {
    // Perform logout actions (e.g., remove items from cookies)
    eraseCookie("user");
    eraseCookie("roleId");
    eraseCookie("selectedAddress");

    closeMenu(); // Close the menu after logout actions are completed
    navigate("/Login"); // Navigate to the login page
  };

  const handleMenuItemHover = (event: React.MouseEvent<HTMLDivElement>) => {
    const menuItem = event.target as HTMLDivElement;
    menuItem.style.backgroundColor = "#000000";
    menuItem.style.color = "#8dc63f";
    const icons = Array.from(
      menuItem.getElementsByTagName("span"),
    ) as HTMLSpanElement[];
    icons.forEach((icon) => {
      icon.style.color = "#8dc63f"; // Change icon color to green
    });
  };

  const handleMenuItemLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    const menuItem = event.target as HTMLDivElement;
    menuItem.style.color = "white"; // Reset text color to white
    const icons = Array.from(
      menuItem.getElementsByTagName("span"),
    ) as HTMLSpanElement[];
    icons.forEach((icon) => {
      icon.style.color = "white"; // Reset icon color to white
    });
  };

  useEffect(() => {
    const storedUser = getCookie("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);

      const fetchUserAddresses = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DB_URL}/user-addresses/user/${user.id}`,
          );
          const data: UserAddress[] = await response.json();
          setUserAddresses(data);

          const storedSelectedAddressId = getCookie("selectedAddress");
          if (storedSelectedAddressId) {
            setSelectedAddress(storedSelectedAddressId);
          } else if (data.length > 0) {
            setSelectedAddress(data[0].id);
            setCookie("selectedAddress", data[0].id, 7); // Cookie expires in 7 days
          }
        } catch (error) {
          console.error("Error fetching user addresses:", error);
        }
      };

      fetchUserAddresses();
    }
  }, []);

  const screenWidth = window.innerWidth;

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedAddress(selectedValue);
    setCookie("selectedAddress", selectedValue, 7); // Cookie expires in 7 days
    window.location.reload(); // Reload the page to reflect the changes
  };

  return (
    <div className="navigation" style={navigationStyles}>
      {isMenuVisible && (
        <div style={closeIconStyles} onClick={closeMenu}>
          <IoClose size={24} />
        </div>
      )}

      <div className="containernavigationmenu">
        {screenWidth < 500 && (
          <div className="select-containernav">
            {userAddresses.length > 0 && (
              <div className="menuItem">
                <p>Verbruiksadres selecteren</p>
                <select
                  value={selectedAddress || ""}
                  onChange={handleSelectChange}
                >
                  <option value="">Select an address</option>
                  {userAddresses.map((address) => (
                    <option key={address.id} value={address.id}>
                      {address.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        )}
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/IndexCustomer")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoGrid />
          </span>{" "}
          Dashboard
        </div>

        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/ChangeEnergyContractCustomer")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoReceipt />
          </span>{" "}
          Energievergelijker
        </div>

        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/Services")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoReceipt />
          </span>{" "}
          Diensten
        </div>

        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/Quotes")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoReceipt />
          </span>{" "}
          Technieken
        </div>

        <a
  href="#beursSection"
  onClick={() => navigateAndCloseMenu("/IndexData")}
  style={{ color: "white", textDecoration: "none", cursor: "pointer" }} // Keep it white, no underline, and pointer cursor
>
  <div
    className="menuItem"
    style={menuItemStyles}
    onMouseEnter={handleMenuItemHover}
    onMouseLeave={handleMenuItemLeave}
  >
    <span style={{ marginRight: "5px" }}>
      <PiHandCoins />
    </span>
    Beursinformatie
  </div>
</a>


        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/Advice")}
        >
          <span style={{ marginRight: "5px" }}>
            <PiHandCoins />
          </span>{" "}
          Tips & Advies
        </div>
        {/* 
        <a href="#adviceSection" onClick={() => navigateAndCloseMenu('/Advice')}>
          <div
            className="menuItem"
            style={menuItemStyles}
            onMouseEnter={handleMenuItemHover}
            onMouseLeave={handleMenuItemLeave}
          >
            <span style={{ marginRight: '5px' }}><PiHandCoins /></span> Tips
          </div>
        </a> */}

        {/* <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
        >
          <Link to="https://www.energyneeds.be/category/app/" target="_blank">
            <span style={{ marginRight: "5px" }}>
              <IoChatbubbles />
            </span>{" "}
            Nieuws
          </Link>
        </div> */}

<div
  className="menuItem"
  style={menuItemStyles}
  onMouseEnter={handleMenuItemHover}
  onMouseLeave={handleMenuItemLeave}
>
  <Link
    to="https://www.energyneeds.be/category/app/"
    target="_blank"
    style={{ color: "white", textDecoration: "none" }} // Set color to white and remove underline
  >
    <span style={{ marginRight: "5px" }}>
      <IoChatbubbles />
    </span>
    Nieuws
  </Link>
</div>


        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/PricesAndAbbos")}
        >
          <span style={{ marginRight: "5px" }}>
            <PiHandCoins />
          </span>{" "}
          Prijzen en abonnementen
        </div>

        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/Faq")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoReceipt />
          </span>{" "}
          FAQ
        </div>
       
        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/Partners")}
        >
          <span style={{ marginRight: "5px" }}>
            <FaHandsHelping />
          </span>{" "}
          Partners
        </div>

       

        <div
          className="menuItem"
          style={menuItemStyles}
          onMouseEnter={handleMenuItemHover}
          onMouseLeave={handleMenuItemLeave}
          onClick={() => navigateAndCloseMenu("/Contact")}
        >
          <span style={{ marginRight: "5px" }}>
            <IoChatbubbles />
          </span>{" "}
          Contact
        </div>
      </div>
    </div>
  );
};

export default NavigationCustomer;
