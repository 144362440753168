import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import "../Articles.css";
import houseisolationImage from "./houseisolation.jpg";
import { getCookie } from "../../../../../cookieUtils";

const HouseIsolationArticle = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div className="full-page-background">
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHeroColored>
            Waarom is het belangrijk je huis goed te isoleren om warmteverlies te voorkomen?
          </PageHeroColored>

          <div className="page-container">
            <NavigationCustomer isMenuVisible={isMenuVisible} toggleMenu={handleToggleMenu} />

            <div className="content-container">
              {/* Featured Image */}
              <div className="featured-image">
                <img src={houseisolationImage} alt="House Isolation" />
              </div>

              {/* Blog Content */}
              <div className="blog-content">
                <p>
                  <b>
                    Een goed geïsoleerd huis is essentieel voor een comfortabele en energiezuinige leefomgeving. Goede isolatie helpt om warmteverlies te voorkomen, wat niet alleen zorgt voor een aangename temperatuur in huis, maar ook bijdraagt aan lagere energiekosten en een verminderde milieubelasting.
                  </b>
                </p>

                <h2>Energiebesparing</h2>
                <p>
                  Een goed geïsoleerd huis zorgt ervoor dat warmte in de winter binnen blijft en in de zomer buiten. Hierdoor hoef je minder te stoken in de winter en blijft het huis koeler in de zomer, zonder dat je extra moet koelen. Dit resulteert in aanzienlijke energiebesparingen en een verlaagde afhankelijkheid van verwarming en koelingssystemen, wat resulteert in lagere energierekeningen en een vermindering van de CO2-uitstoot.
                </p>

                <h2>Comfort en gezondheid</h2>
                <p>
                  Een goed geïsoleerd huis zorgt voor een gelijkmatige en comfortabele binnentemperatuur. Geen koude tocht in de winter of oververhitting in de zomer. Dit draagt bij aan een gezonde leefomgeving en vermindert het risico op vochtproblemen en schimmelvorming.
                </p>

                <h2>Milieuvriendelijk</h2>
                <p>
                  Door je huis goed te isoleren, draag je bij aan een duurzamere samenleving. Minder energieverbruik betekent minder uitstoot van broeikasgassen en vermindering van de impact op het milieu. Huisisolatie is een effectieve manier om je ecologische voetafdruk te verkleinen.
                </p>

                <h2>Conclusie</h2>
                <p>
                  Het goed isoleren van je huis heeft talloze voordelen. Naast de energiebesparing en kostenverlaging, zorgt huisisolatie voor een comfortabele en gezonde leefomgeving. Investeer in huisisolatie en pluk de vruchten van een energiezuinig, comfortabel en milieuvriendelijk huis.
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div>Access Restricted</div>
      )}
    </>
  );
};

export default HouseIsolationArticle;
