import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IoClose } from "react-icons/io5";
import "./ContractDetailsPopup.css";
import { getCookie } from "../../../../../cookieUtils";
import {
  ContractGasDetails,
  NetCostsZipCodeGas,
} from "../../../../../interfaces/interfaces";

interface ContractDetailsProps {
  contractId: string;
  onClose: () => void;
}

const ContractDetailsPopupGas: React.FC<ContractDetailsProps> = ({
  contractId,
  onClose,
}) => {
  const [contractDetails, setContractDetails] =
    useState<ContractGasDetails | null>(null);
  const [netCostsZipCodeGas, setNetCostsZipCodeGas] =
    useState<NetCostsZipCodeGas | null>(null);

  const zipcode = getCookie("postcode");
  const excludedVerbruikValue = getCookie("verbruikValue");
  const verbruikValue = excludedVerbruikValue
    ? parseFloat(excludedVerbruikValue)
    : 0;

  const fetchContractDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/ContractGasDetails/${contractId}`,
      );
      setContractDetails(response.data);
    } catch (error) {
      console.error("Error retrieving contract details:", error);
    }
  };

  const fetchNetCostsZipCodeGas = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/netCostsGas/${zipcode}`,
      );
      setNetCostsZipCodeGas(response.data);
    } catch (error) {
      console.error("Error retrieving zipcode details:", error);
    }
  };

  useEffect(() => {
    fetchContractDetails();
    fetchNetCostsZipCodeGas();
  }, [contractId]);

  if (!contractDetails) {
    return <div>Loading...</div>;
  }

  const tableRows = [
    {
      key: "Jaarlijks abonnement",
      label: "Jaarlijks abonnement",
      unitPrice: "",
      totalPrice: `${contractDetails.yearlySubscription?.toFixed(2)} €`,
      condition: true,
    },
    {
      key: "kWh verbruikskost",
      label: "kWh verbruikskost",
      unitPrice: `${contractDetails.kwhConsumptionPrice} €/kWh`,
      totalPrice: `${(verbruikValue * contractDetails.kwhConsumptionPrice).toFixed(2)} €`,
      condition: true,
    },
    {
      key: "Nettarieven",
      label: "Nettarieven",
      unitPrice: "",
      totalPrice: "",
      condition: true,
      isDivider: true,
    },
    {
      key: "Distributiekosten (vast)",
      label: "Distributiekosten (vast)",
      unitPrice: "",
      totalPrice: `${(netCostsZipCodeGas?.distributionSmallFixedPrice ?? 0).toFixed(2)} €`,
      condition: verbruikValue < 5000,
    },
    {
      key: "Distributiekosten (variabel)",
      label: "Distributiekosten (variabel)",
      unitPrice: `${netCostsZipCodeGas?.distributionSmallVariablePrice ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeGas?.distributionSmallVariablePrice ?? 0)).toFixed(2)} €`,
      condition: verbruikValue < 5000,
    },
    {
      key: "Transportkosten",
      label: "Transportkosten",
      unitPrice: `${netCostsZipCodeGas?.transportCostPrice ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeGas?.transportCostPrice ?? 0)).toFixed(2)} €`,
      condition: true,
    },
    {
      key: "Tarief databeheer / Meet- en telactiviteit",
      label: "Tarief databeheer / Meet- en telactiviteit",
      unitPrice: "",
      totalPrice: `${netCostsZipCodeGas?.dataManagementPrice?.toFixed(2) ?? 0} €`,
      condition:
        netCostsZipCodeGas?.dataManagementPrice !== undefined &&
        netCostsZipCodeGas?.dataManagementPrice !== 0,
    },
    {
      key: "Heffingen",
      label: "Heffingen",
      unitPrice: "",
      totalPrice: "",
      condition: true,
      isDivider: true,
    },
    {
      key: "Bijdrage op de energie",
      label: "Bijdrage op de energie",
      unitPrice: `${netCostsZipCodeGas?.contributionEnergy ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeGas?.contributionEnergy ?? 0)).toFixed(2)} €`,
      condition: true,
    },
    {
      key: "Bijzondere accijns",
      label: "Bijzondere accijns",
      unitPrice: `${netCostsZipCodeGas?.taxAccijns ?? 0} €/kWh`,
      totalPrice: `${(verbruikValue * (netCostsZipCodeGas?.taxAccijns ?? 0)).toFixed(2)} €`,
      condition: true,
    },
  ];

  const calculateTotalYearlyCost = () => {
    const total =
      (contractDetails?.yearlySubscription ?? 0) +
      (contractDetails?.kwhConsumptionPrice ?? 0) * verbruikValue +
      (netCostsZipCodeGas?.distributionSmallFixedPrice ?? 0) +
      verbruikValue *
        (netCostsZipCodeGas?.distributionSmallVariablePrice ?? 0) +
      verbruikValue * (netCostsZipCodeGas?.transportCostPrice ?? 0) +
      (netCostsZipCodeGas?.dataManagementPrice ?? 0) + // Adding dataManagementPrice to the total
      verbruikValue * (netCostsZipCodeGas?.contributionEnergy ?? 0) +
      verbruikValue * (netCostsZipCodeGas?.taxAccijns ?? 0) +
      verbruikValue * (netCostsZipCodeGas?.connectionFee ?? 0);

    return total.toFixed(2);
  };

  return (
    <div className="popupContainer">
      <div className="popupOverlay" onClick={onClose}></div>
      <div className="popupContent">
        <TableContainer sx={{ border: "none" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h2>ContractDetails</h2>
                </TableCell>
                <TableCell align="right"> </TableCell>
                <TableCell align="right">
                  <IoClose size={20} onClick={onClose} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell align="right">
                  <b>Eenheidsprijs</b>
                </TableCell>
                <TableCell align="right">
                  <b>Prijs</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Energieprijs row */}
              <TableRow
                key="energieprijs"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <strong>Energieprijs</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {tableRows
                .filter((row) => row.condition)
                .map((row) =>
                  row.isDivider ? (
                    <TableRow key={row.key}>
                      <TableCell component="th" scope="row">
                        <strong>{row.label}</strong>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={row.key}>
                      <TableCell component="th" scope="row">
                        {row.label}
                      </TableCell>
                      <TableCell align="right">{row.unitPrice}</TableCell>
                      <TableCell align="right">{row.totalPrice}</TableCell>
                    </TableRow>
                  ),
                )}
              <TableRow>
                <TableCell component="th" scope="row">
                  <strong>Totale Jaarlijkse kost</strong>
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  {calculateTotalYearlyCost()} € incl. BTW
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ContractDetailsPopupGas;
