import React, { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarBorderIcon from "@mui/icons-material/StarBorder";

interface ScoreStarsProps {
  energySupplierId: string | null;
}

const MAX_SCORE = 5;

const rootStyle: React.CSSProperties = {
  display: "inline-flex",
  alignItems: "center",
};

const starStyle: React.CSSProperties = {
  color: "#bdbdbd", // Grey color
  fontSize: 24,
};

const filledStarStyle: React.CSSProperties = {
  color: "#fdd835", // Yellow color
  fontSize: 24,
};

const halfStarStyle: React.CSSProperties = {
  color: "#fdd835", // Yellow color
  fontSize: 24,
};

const ScoreStars: React.FC<ScoreStarsProps> = ({ energySupplierId }) => {
  const [score, setScore] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEvaluation = async () => {
      if (!energySupplierId) return;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_DB_URL}/energySuppliers/${energySupplierId}`,
        );
        const data = await response.json();
        if (response.ok) {
          setScore(data.evaluation);
        } else {
          setError(data.error || "Failed to fetch evaluation");
        }
      } catch (error) {
        setError(
          "Er ging iets mis tijdens het ophalen van de tevredenheidscore.",
        );
      } finally {
        setLoading(false);
      }
    };

    fetchEvaluation();
  }, [energySupplierId]);

  const renderStars = () => {
    const stars = [];
    if (score !== null) {
      const fullStars = Math.floor(score);
      const hasHalfStar = score % 1 !== 0;

      for (let i = 1; i <= MAX_SCORE; i++) {
        if (i <= fullStars) {
          stars.push(<StarIcon key={i} style={filledStarStyle} />);
        } else if (i === fullStars + 1 && hasHalfStar) {
          stars.push(<StarHalfIcon key={i} style={halfStarStyle} />);
        } else {
          stars.push(<StarBorderIcon key={i} style={starStyle} />);
        }
      }
    }
    return stars;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div style={rootStyle}>{renderStars()}</div>;
};

export default ScoreStars;
