import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./AddContract.css";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import MenuItem from "@mui/material/MenuItem";
import { v4 as uuidv4 } from "uuid";
import { useDropzone } from "react-dropzone";
import PageHero from "../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../cookieUtils";

interface EnergySupplier {
  id: string;
  name: string;
}

interface ContractElectricityDetails {
  id: string;
  contractId: string;
  yearlySubscription: number;
  kwhConsumptionPrice: number;
  kwhConsumptionPriceDiscount: number;
  kwhConsumptionPriceAfterDiscount: number;
  kwhConsumptionPriceTwoDay: number;
  kwhConsumptionPriceTwoNight: number;
  kwhConsumptionPriceNight: number;
  fixedPriceElectricity: number;
  hewwkContribution: number;
  dataManagementPrice: number;
  capacityRatePrice: number;
  purchaseRatePrice: number;
  taxPrice: number;
  totalConumptionCostPrice: number;
  kwhInjectionSolarPanelsDiscountPrice: number;
}

interface DocumentTemplate {
  id: string;
  documentPath: string;
  documentName: string;
  status: number;
}

const AddContract = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [partnerId, setPartnerId] = useState("12345");
  const [category, setCategory] = useState(0);
  const [supplierId, setSupplierId] = useState("");
  const [energySuppliers, setEnergySuppliers] = useState<EnergySupplier[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMenuVisible, setMenuVisible] = useState(false);
  // const [contractElectricityDetailsId, setcontractElectricityDetailsId] = useState("");
  const [yearlySubscription, setyearlySubscription] = useState(0.01);
  const [kwhConsumptionPrice, setkwhConsumptionPrice] = useState(0.01);
  const [kwhConsumptionPriceDiscount, setkwhConsumptionPriceDiscount] =
    useState(0.01);
  const [
    kwhConsumptionPriceAfterDiscount,
    setkwhConsumptionPriceAfterDiscount,
  ] = useState(0.01);

  const [kwhConsumptionPriceTwoDay, setKwhConsumptionPriceTwoDay] =
    useState(0.01);
  const [kwhConsumptionPriceTwoNight, setKwhConsumptionPriceTwoNight] =
    useState(0.01);
  const [kwhConsumptionPriceNight, setKwhConsumptionPriceNight] =
    useState(0.01);
  const [fixedPriceElectricity, setFixedPriceElectricity] = useState(0.01);

  const [hewwkContribution, sethewwkContribution] = useState(0.01);
  const [dataManagementPrice, setdataManagementPrice] = useState(0.01);
  const [totalConumptionCostPrice, settotalConumptionCostPrice] =
    useState(0.01);
  const [capacityRatePrice, setcapacityRatePrice] = useState(0.01);
  const [purchaseRatePrice, setpurchaseRatePrice] = useState(0.01);
  const [taxPrice, settaxPrice] = useState(0.01);
  const [
    kwhInjectionSolarPanelsDiscountPrice,
    setkwhInjectionSolarPanelsDiscountPrice,
  ] = useState(0.01);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [hasUploadedDocument, setHasUploadedDocument] = useState(false);
  const [showDropField, setShowDropField] = useState(false);
  const [documentTemplateId, setDocumentTemplateId] = useState<string | null>(
    null,
  );

  const [minimalDurationLength, setMinimalDurationLength] = useState(0);
  const [commission, setCommission] = useState(0.01);
  const [clawback, setClawback] = useState(0.01);
  const [VME, setVME] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchEnergySuppliers();
  }, []);

  const fetchEnergySuppliers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      const suppliers: EnergySupplier[] = response.data;
      setEnergySuppliers(suppliers);
    } catch (error) {
      console.error("Error fetching energy suppliers:", error);
    }
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handleSupplierIdChange = (event: any) => {
    setSupplierId(event.target.value);
  };

  const handleYearlySubscriptionChange = (event: any) => {
    const categorychange = parseFloat(event.target.value);
    setyearlySubscription(categorychange);
  };

  const handleKwhConsumptionPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setkwhConsumptionPrice(categorychange);
  };

  const handleKwhConsumptionPriceDiscountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setkwhConsumptionPriceDiscount(categorychange);
  };
  const handleKwhConsumptionPriceAfterDiscountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setkwhConsumptionPriceAfterDiscount(categorychange);
  };
  const handleHewwkContributionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    sethewwkContribution(categorychange);
  };
  const handleDataManagementPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setdataManagementPrice(categorychange);
  };
  const handleTotalConsumptionCostPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    settotalConumptionCostPrice(categorychange);
  };
  const handleCapacityRatePriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setcapacityRatePrice(categorychange);
  };
  const handlePurchaseRatePriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const categorychange = parseFloat(event.target.value);
    setpurchaseRatePrice(categorychange);
  };
  const handleTaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const categorychange = parseFloat(event.target.value);
    settaxPrice(categorychange);
  };

  const handleKwhConsumptionPriceTwoDay = (event: any) => {
    setKwhConsumptionPriceTwoDay(event.target.value);
  };

  const handleKwhConsumptionPriceTwoNight = (event: any) => {
    setKwhConsumptionPriceTwoNight(event.target.value);
  };
  const handleKwhConsumptionPriceNight = (event: any) => {
    setKwhConsumptionPriceNight(event.target.value);
  };
  const handleFixedPriceElectricity = (event: any) => {
    setFixedPriceElectricity(event.target.value);
  };

  const handleKwhInjectionSolarPanelsDiscountPriceChange = (event: any) => {
    const categorychange = parseFloat(event.target.value);
    setkwhInjectionSolarPanelsDiscountPrice(categorychange);
  };
  const handleCategoryChange = (event: any) => {
    const categorychange = parseFloat(event.target.value);
    setCategory(categorychange);
  };

  const handleMinimalDurationLength = (event: any) => {
      const intValue = parseInt(event.target.value, 10);
      setMinimalDurationLength(isNaN(intValue) ? 0 : intValue); // Set to 0 if conversion fails
    
  };

  const handleCommission = (event: any) => {
    setCommission(event.target.value);
  };

  const handleClawback = (event: any) => {
    setClawback(event.target.value);
  };

  const handleVME = (event: any) => {
    setVME(event.target.value);
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      const formData = new FormData();

      acceptedFiles.forEach((file) => {
        if (file.type === "application/pdf") {
          formData.append("document", file);
          formData.append("documentName", file.name);
        } else {
          console.log("Invalid file format. Only PDF files are allowed.");
        }
      });

      if (formData.has("document")) {
        const documentTemplateId = uuidv4(); // Generate a UUID for the document
        const id = documentTemplateId.toString();
        setDocumentTemplateId(documentTemplateId);
        formData.append("id", id); // Include the UUID in the form data

        await axios.post(
          `${process.env.REACT_APP_DB_URL}/documentTemplates`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );

        console.log("Files uploaded successfully");
        setHasUploadedDocument(true);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  }, []);

  // const handleAddContract = async () => {
  //   try {
  //     const contractId = uuidv4(); // Generate a unique ID for the contract
  //     const contractElectricityDetails = {
  //       contractId,
  //       yearlySubscription,
  //       kwhConsumptionPrice,
  //       kwhConsumptionPriceDiscount,
  //       kwhConsumptionPriceAfterDiscount,
  //       kwhConsumptionPriceTwoDay,
  //       kwhConsumptionPriceTwoNight,
  //       kwhConsumptionPriceNight,
  //       fixedPriceElectricity,
  //       hewwkContribution,
  //       dataManagementPrice,
  //       capacityRatePrice,
  //       purchaseRatePrice,
  //       taxPrice,
  //       totalConumptionCostPrice,
  //       kwhInjectionSolarPanelsDiscountPrice,
  //     };

  //     // Step 1: Create the contractElectricityDetails record
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_DB_URL}/contractElectricityDetails`,
  //       contractElectricityDetails,
  //     );
  //     const contractElectricityDetailsId =
  //       response.data.contractElectricityDetails.id;

  //     const id = uuidv4();
  //     // Step 2: Create the main contract
  //     const newContract = {
  //       id,
  //       name,
  //       description,
  //       category,
  //       supplierId,
  //       documentTemplateId: documentTemplateId,
  //       contractDetailsId: contractElectricityDetailsId,
  //       minimalDurationLength,
  //       commission,
  //       clawback,
  //       VME,
  //     };
  //     await axios.post(
  //       `${process.env.REACT_APP_DB_URL}/contracts`,
  //       newContract,
  //     );
  //     console.log("Contract created successfully");

  //     // Step 3: Update contractElectricityDetails with the correct contractId
  //     const updatedContractElectricityDetails = {
  //       contractId: newContract.id,
  //       // Add other properties as needed for updating the record
  //     };
  //     await axios.put(
  //       `${process.env.REACT_APP_DB_URL}/contractElectricityDetails/${contractElectricityDetailsId}`,
  //       updatedContractElectricityDetails,
  //     );
  //     console.log("contractElectricityDetails updated successfully");

  //     navigate("/Contracts");
  //   } catch (error) {
  //     console.error("Error creating contract:", error);
  //     // Handle error state or display an error message
  //   }
  // };

  const handleAddContract = async () => {
    try {
      const contractId = uuidv4(); // Generate a unique ID for the contract
      const contractElectricityDetails = {
        contractId,
        yearlySubscription,
        kwhConsumptionPrice,
        kwhConsumptionPriceDiscount,
        kwhConsumptionPriceAfterDiscount,
        kwhConsumptionPriceTwoDay,
        kwhConsumptionPriceTwoNight,
        kwhConsumptionPriceNight,
        fixedPriceElectricity,
        hewwkContribution,
        dataManagementPrice,
        capacityRatePrice,
        purchaseRatePrice,
        taxPrice,
        partnerId,
        totalConumptionCostPrice,
        kwhInjectionSolarPanelsDiscountPrice,
      };
  
      // Step 1: Create the contractElectricityDetails record
      const response = await axios.post(
        `${process.env.REACT_APP_DB_URL}/contractElectricityDetails`,
        contractElectricityDetails,
      );
      const contractElectricityDetailsId = response.data.contractElectricityDetails.id;
  
      const id = uuidv4();
      // Set the documentTemplateId if it's empty
      const resolvedDocumentTemplateId = documentTemplateId || "a7ca8ae0-04fc-457c-bf52-4df362600000";
  

      // set minimalDurationLength into int
//      const minimalDurationLengthToInt = parseInt(minimalDurationLength);

      // Step 2: Create the main contract
      const newContract = {
        id,
        name,
        description,
        category,
        supplierId,
        documentTemplateId: resolvedDocumentTemplateId,
        contractDetailsId: contractElectricityDetailsId,
        minimalDurationLength,
        commission,
        clawback,
        partnerId,
        VME,
      };
      await axios.post(`${process.env.REACT_APP_DB_URL}/contracts`, newContract);
      console.log("Contract created successfully");
  
      // Step 3: Update contractElectricityDetails with the correct contractId
      const updatedContractElectricityDetails = {
        contractId: newContract.id,
      };
      await axios.put(
        `${process.env.REACT_APP_DB_URL}/contractElectricityDetails/${contractElectricityDetailsId}`,
        updatedContractElectricityDetails,
      );
      console.log("contractElectricityDetails updated successfully");
  
      navigate("/Contracts");
    } catch (error) {
      console.error("Error creating contract:", error);
    }
  };
  

  const containerStyles = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleButtonClick = () => {
    setShowDropField(!showDropField);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Contract toevoegen</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <hr className="dividerDashed" />
              {/* <div className="contentContainer"> */}
              <div>
                <div className="formContainer">
                  <h1 className="pageTitle" style={pageTitleStyles}>
                    Contract informatie
                  </h1>
                  {errorMessage ? (
                    <p className="errorMessage">{errorMessage}</p>
                  ) : null}

                  <div className="userCardac">
                    <div className="userCardContainerac">
                      <div className="userCardColumnac">
                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <ElectricBoltIcon
                              sx={{ color: "action.active", mr: 1, my: 0.5 }}
                            />
                            <TextField
                              label="Naam"
                              variant="standard"
                              type="text"
                              id="nameFilter"
                              value={name}
                              onChange={handleNameChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <ElectricBoltIcon
                              sx={{ color: "action.active", mr: 1, my: 0.5 }}
                            />
                            <TextField
                              label="Beschrijving"
                              variant="standard"
                              type="text"
                              value={description}
                              onChange={handleDescriptionChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="category"
                              select
                              label="Categorie"
                              value={category}
                              onChange={handleCategoryChange}
                              variant="standard"
                              fullWidth
                            >
                              <MenuItem value={0}>
                                Selecteer een categorie
                              </MenuItem>
                              <MenuItem value={1}>Elektriciteit Vast</MenuItem>
                              <MenuItem value={2}>
                                Elektriciteit Variabel
                              </MenuItem>
                              <MenuItem value={5}>
                                Elektriciteit Dynamisch
                              </MenuItem>
                              <MenuItem value={10}>
                                Elektriciteit Vast Professioneel
                              </MenuItem>
                              <MenuItem value={11}>
                                Elektriciteit Variabel Professioneel
                              </MenuItem>
                              <MenuItem value={14}>
                                Elektriciteit Dynamisch Professioneel
                              </MenuItem>
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              id="Energieleverancier"
                              select
                              label="Energieleverancier"
                              value={supplierId}
                              onChange={handleSupplierIdChange}
                              variant="standard"
                              fullWidth
                            >
                              <MenuItem value={0}>
                                Selecteer een energieleverancier
                              </MenuItem>
                              {energySuppliers.map((supplier) => (
                                <MenuItem key={supplier.id} value={supplier.id}>
                                  {supplier.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Minimale looptijd"
                              variant="standard"
                              type="number"
                              id="minimaldurationlength"
                              value={minimalDurationLength}
                              onChange={handleMinimalDurationLength}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Commissie"
                              variant="standard"
                              type="number"
                              id="commission"
                              value={commission}
                              onChange={handleCommission}
                              fullWidth
                            />
                          </Box>
                        </div>

                        {/* { hasUploadedDocument === false ? (<button className="uploadButton" onClick={handleButtonClick}>
              {showDropField ? "Annuleren" : "Bestanden uploaden"}
            </button> ) : (<div></div>)} */}
                      </div>
                      {showDropField && hasUploadedDocument === false && (
                        <div
                          {...getRootProps()}
                          className={`dropzone ${isDragActive ? "active" : ""}`}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>Drop the files here...</p>
                          ) : (
                            <p>
                              Klik op deze tekst om een bestand te selecteren of
                              sleep het document in dit vak.
                            </p>
                          )}
                        </div>
                      )}

                      {/* price details forms */}
                      <div className="userCardColumnac">
                        {/* <label className="label">Jaarlijks abonnement:</label>
             <input type="number" className="input" step={0.01} value={yearlySubscription} onChange={handleYearlySubscriptionChange} /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Clawback"
                              variant="standard"
                              type="number"
                              id="clawback"
                              value={clawback}
                              onChange={handleClawback}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="VME"
                              variant="standard"
                              type="number"
                              id="vme"
                              value={VME}
                              onChange={handleVME}
                              fullWidth
                            />
                          </Box>
                        </div>

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Jaarlijks abonnement"
                              variant="standard"
                              type="number"
                              id="abbofilteryearly"
                              value={yearlySubscription}
                              onChange={handleYearlySubscriptionChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        {/* <label className="label">kWh verbruikskost:</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPrice} onChange={handleKwhConsumptionPriceChange} /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Enkelvoudig tarief"
                              variant="standard"
                              type="number"
                              id="kwhusage"
                              value={kwhConsumptionPrice}
                              onChange={handleKwhConsumptionPriceChange}
                              fullWidth
                            />
                          </Box>
                        </div>

                        {/* <label className="label">Tweevoudig tarief (dag):</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPriceTwoDay} onChange={handleKwhConsumptionPriceTwoDay} /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Tweevoudig tarief (dag)"
                              variant="standard"
                              type="number"
                              id="twoday"
                              value={kwhConsumptionPriceTwoDay}
                              onChange={handleKwhConsumptionPriceTwoDay}
                              fullWidth
                            />
                          </Box>
                        </div>

                        {/* 
             <label className="label">Tweevoudig tarief (nacht):</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPriceTwoNight} onChange={handleKwhConsumptionPriceTwoNight} /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Tweevoudig tarief (nacht)"
                              variant="standard"
                              type="number"
                              id="twonight"
                              value={kwhConsumptionPriceTwoNight}
                              onChange={handleKwhConsumptionPriceTwoNight}
                              fullWidth
                            />
                          </Box>
                        </div>

                        {/* <label className="label">Exclusief nacht tarief:</label>
             <input type="number" className="input" step={0.01} value={kwhConsumptionPriceNight} onChange={handleKwhConsumptionPriceNight} /> */}

                        <div className="form-group">
                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                              label="Exclusief nacht tarief"
                              variant="standard"
                              type="number"
                              id="exclnight"
                              value={kwhConsumptionPriceNight}
                              onChange={handleKwhConsumptionPriceNight}
                              fullWidth
                            />
                          </Box>
                        </div>
                      </div>

                      <button
                        className="updateButton"
                        onClick={handleAddContract}
                      >
                        Contract toevoegen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default AddContract;
