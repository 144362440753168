import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import "../Articles.css";
import solarpanelsimage from "./shutdownelectronics.jpg";
import { getCookie } from "../../../../../cookieUtils";

const ShutdownElectronicsArticle = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div className="full-page-background">
          <NavBar toggleMenu={handleToggleMenu}  />
          <PageHeroColored>
            Waarom is het belangrijk om apparaten volledig uit te schakelen en niet op stand-by te laten staan?
          </PageHeroColored>

          <div className="page-container">
            <NavigationCustomer isMenuVisible={isMenuVisible} toggleMenu={handleToggleMenu} />

            <div className="content-container">
              {/* Featured Image */}
              <div className="featured-image">
                <img src={solarpanelsimage} alt="Shutdown Electronics" />
              </div>

              {/* Blog Content */}
              <div className="blog-content">
                <p>
                  <b>
                    Veel mensen zijn zich er niet van bewust dat elektronische apparaten, zelfs wanneer ze in stand-by modus staan, nog steeds energie verbruiken. In dit artikel bespreken we waarom het belangrijk is om apparaten volledig uit te schakelen en de voordelen hiervan voor zowel je portemonnee als het milieu.
                  </b>
                </p>

                <h2>Energieverbruik in stand-by</h2>
                <p>
                  Elektronische apparaten zoals televisies, computers, gameconsoles en opladers blijven vaak in stand-by modus staan wanneer ze niet actief worden gebruikt. Dit sluipverbruik kan tot wel 10% van het totale elektriciteitsverbruik van een huishouden vertegenwoordigen.
                </p>

                <h2>Milieueffect</h2>
                <p>
                  Sluipverbruik zorgt voor onnodige CO2-uitstoot en draagt bij aan klimaatverandering. Door apparaten volledig uit te schakelen wanneer je ze niet gebruikt, kun je je ecologische voetafdruk verkleinen en de milieubelasting verminderen.
                </p>

                <h2>Veiligheid</h2>
                <p>
                  Het volledig uitschakelen van apparaten draagt ook bij aan een veiligere omgeving. Elektronische apparaten die in stand-by modus blijven staan, kunnen nog steeds warmte produceren en het risico op brand of oververhitting vergroten.
                </p>

                <h2>Conclusie</h2>
                <p>
                  Het volledig uitschakelen van elektronische apparaten is een eenvoudige manier om energie te besparen, je energierekening te verlagen, en een positieve impact te hebben op het milieu. Daarnaast draagt het bij aan een veiligere omgeving in huis en een duurzamere toekomst.
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div>Access Restricted</div>
      )}
    </>
  );
};

export default ShutdownElectronicsArticle;
