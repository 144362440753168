import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import "../Articles.css";
import solarpanelsimage from "./solarpanels.jpg";
import { getCookie } from "../../../../../cookieUtils";

const SolarPanelsArticle = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div className="full-page-background">
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHeroColored>Waarom zijn zonnepanelen voordelig?</PageHeroColored>

          <div className="page-container">
            <NavigationCustomer isMenuVisible={isMenuVisible} toggleMenu={handleToggleMenu} />

            <div className="content-container">
              {/* Featured Image */}
              <div className="featured-image">
                <img src={solarpanelsimage} alt="Solar Panels" />
              </div>

              {/* Blog Content */}
              <div className="blog-content">
                <p>
                  <b>
                    Zonnepanelen zijn een uitstekende investering voor huiseigenaren en bedrijven. Ze bieden talloze voordelen, waaronder het verminderen van uw energierekening, het verminderen van uw afhankelijkheid van het elektriciteitsnet en het verminderen van uw ecologische voetafdruk.
                  </b>
                </p>

                <h2>Schone en hernieuwbare energie</h2>
                <p>
                  Het grootste voordeel van zonnepanelen is dat ze gebruikmaken van een hernieuwbare en schone energiebron: de zon. In tegenstelling tot fossiele brandstoffen, die schadelijke broeikasgassen produceren, stoten zonnepanelen geen vervuilende stoffen uit.
                </p>

                <h2>Verlaging van de elektriciteitskosten</h2>
                <p>
                  Zodra de panelen zijn geïnstalleerd, kunt u profiteren van gratis elektriciteit van de zon, waardoor u minder elektriciteit van uw energieleverancier afneemt en lagere maandelijkse kosten hebt.
                </p>

                <h2>Energieonafhankelijkheid</h2>
                <p>
                  Bovendien kunnen zonnepanelen u helpen om minder afhankelijk te zijn van het elektriciteitsnet. Op zonnige dagen kunnen ze meer elektriciteit produceren dan u verbruikt, waardoor het overschot terug wordt geleverd aan het net, wat kan resulteren in een krediet op uw elektriciteitsrekening.
                </p>

                <h2>Conclusie</h2>
                <p>
                  Zonnepanelen zijn niet alleen goed voor het milieu, maar ook voor uw portemonnee. Door te investeren in zonnepanelen kunt u uw energiekosten verlagen en bijdragen aan een duurzamere toekomst. Neem contact op met een professionele zonnepaneleninstallateur om de mogelijkheden te bespreken.
                </p>
               
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div>Access Restricted</div>
      )}
    </>
  );
};

export default SolarPanelsArticle;
