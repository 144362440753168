import React, { useEffect, useState } from "react";
import NavBar from "../../../../components/Navigation/NavBar";
import { useNavigate } from "react-router";
import NavigationCustomer from "../../../../components/customer/NavigationCustomer";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import axios from "axios";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Divider } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Cell,
  Pie,
  Area,
  AreaChart,
  ResponsiveContainer,
} from "recharts";
import Footer from "../../../../components/Footer/Footer";
import PageHero from "../../../../components/Pagehero/PageHero";
import SectionHeroColored from "../../../../components/SectionheroColored/SectionHeroColored";
import CenterTitle from "../../../../components/CenterTitle/CenterTitle";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { IoFlame } from "react-icons/io5";
import "./IndexData.css";
import { getCookie } from "../../../../cookieUtils";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface BelpexData {
  Date: Date;
  Euro: number;
}

interface BelixData {
  Date: Date;
  Euro: number;
}

interface DamData {
  Date: Date;
  Euro: number;
}

interface EndexData {
  Date: Date;
  Euro: number;
}

interface ZtpData {
  Date: Date;
  Euro: number;
}

const IndexData: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [belpexs, setBelpexs] = useState<BelpexData[]>([]);
  const [belixs, setBelixs] = useState<BelixData[]>([]);
  const [dam, setDam] = useState<DamData[]>([]);
  const [endex, setEndex] = useState<EndexData[]>([]);
  const [ztp, setZtp] = useState<ZtpData[]>([]);
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedTimeframe, setSelectedTimeframe] = useState("day");
  const [selectedTimePeriodBelpex, setSelectedTimePeriodBelpex] =
    useState<string>("jaarlijks");
  const [selectedTimePeriodTTF, setSelectedTimePeriodTTF] =
    useState<string>("jaarlijks");
  const [selectedTimePeriodDam, setSelectedTimePeriodDam] =
    useState<string>("jaarlijks");
  const [selectedTimePeriodZTP, setSelectedTimePeriodZTP] =
    useState<string>("jaarlijks");
  const [belpexsFact, setBelpexsFact] = useState<number>(0);
  const [belixsFact, setBelixsFact] = useState<number>(0);
  const [damFact, setDamFact] = useState<number>(0);
  const [ztpFact, setZtpFact] = useState<number>(0);
  // Function to calculate background color based on belpextfact value
  const calculateBackgroundColor = (value: any) => {
    if (value < 0) {
      return "green";
    } else if (value === 0) {
      return "black";
    } else {
      return "red";
    }
  };

  // Get the calculated background color for belixsFact
  const backgroundColorBelix = calculateBackgroundColor(belixsFact);

  // Get the calculated background color for belpextfact
  const backgroundColorBelpexs = calculateBackgroundColor(belpexsFact);

  // Get the calculated background color for damFact
  const backgroundColorDam = calculateBackgroundColor(damFact);

  // Get the calculated background color for belpextfact
  const backgroundColorZtp = calculateBackgroundColor(ztpFact);

  const arrowSize = "25px"; // Adjust the size as needed

  const arrowIconBelpexs =
    belpexsFact > 0 ? (
      <ArrowUpwardIcon style={{ fontSize: arrowSize }} />
    ) : belpexsFact < 0 ? (
      <ArrowDownwardIcon style={{ fontSize: arrowSize }} />
    ) : null;
  const arrowIconBelix =
    belixsFact > 0 ? (
      <ArrowUpwardIcon style={{ fontSize: arrowSize }} />
    ) : belixsFact < 0 ? (
      <ArrowDownwardIcon style={{ fontSize: arrowSize }} />
    ) : null;
  const arrowIconDam =
    damFact > 0 ? (
      <ArrowUpwardIcon style={{ fontSize: arrowSize }} />
    ) : damFact < 0 ? (
      <ArrowDownwardIcon style={{ fontSize: arrowSize }} />
    ) : null;
  const arrowIconZtp =
    ztpFact > 0 ? (
      <ArrowUpwardIcon style={{ fontSize: arrowSize }} />
    ) : ztpFact < 0 ? (
      <ArrowDownwardIcon style={{ fontSize: arrowSize }} />
    ) : null;

  const handleTimeframeChange = (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    setSelectedTimeframe(event.target.value);
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    padding: "20px",
    transition: "all 0.3s",
    boxShadow: "none", // Remove the box shadow
  };

  const squareContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    marginTop: "20px",
    flexWrap: "wrap",
  };
  const squareRowStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center", // Center the squares horizontally within the row
    gap: "20px", // Add space between squares
    marginTop: "20px", // Add some top margin to each row
  };

  const squareStyles: React.CSSProperties = {
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
  };

  const squareCouStyles: React.CSSProperties = {
    flex: "1",
    height: "120px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "20px",
    marginTop: "30px",
    alignItems: "flex-start",
    paddingLeft: "10px",
    position: "relative",
    paddingTop: "15px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow
  };

  const buttonStyles: React.CSSProperties = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    backgroundColor: "transparent",
    outline: "0px",
    margin: "0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontFamily: "Montserrat, sans-serif",
    lineHeight: "1.75",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "2px solid",
    width: "100%",
    textTransform: "none",
    borderRadius: "4px",
    boxShadow: "none",
    padding: "0.6rem 1.5rem",
    fontSize: "12px",
    fontWeight: 600,
    minWidth: "84px",
    //color: 'rgb(18, 31, 67)',
    color: "#8dc63f",
    borderColor: "#8dc63f",
  };

  const squareContainerStylesNoti: React.CSSProperties = {
    display: "flex",
    justifyContent: "left", // Center the squares horizontally
    flexWrap: "wrap", // Allow squares to wrap to the next line if needed
    gap: "20px", // Add space between squares
    marginTop: "20px", // Add some top margin to the square container
  };

  const squareStylesNoti: React.CSSProperties = {
    width: "250px",
    height: "125px",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  };

  const bottomBorderStyles: React.CSSProperties = {
    content: "",
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "5px", // Adjust this value to control the thickness of the border
    backgroundColor: "#8dc63f", // Color of the bottom border
    borderBottomLeftRadius: "8px", // Rounded bottom left corner
    borderBottomRightRadius: "8px", // Rounded bottom right corner
  };

  const dashboardContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  };

  const dashboardContainerCouStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  };

  const chartContainerStyles: React.CSSProperties = {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    // flexDirection: "column",
    marginBottom: "20px",
    background: "#ffffff",
    borderRadius: "8px",
    alignItems: "center",
    marginLeft: "10px", // Add margin-left
    marginRight: "10px", // Add margin-right
    paddingBottom: "50px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow
  };

  const chartStyles: React.CSSProperties = {
    flex: "1",
    height: "400px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    paddingLeft: "10px",
    position: "relative",
    paddingTop: "15px",
    margin: "0 auto", // Center the charts within the squares
    alignItems: "center", // Center the charts horizontally
  };

  const counterStyles: React.CSSProperties = {
    fontSize: "30px",
    fontWeight: "bold",
  };

  // Styles for beurs data feiten
  const stylesBelpex = {
    fontSize: "40px",
    marginRight: "20px",
    color: "#ffffff",
    backgroundColor: backgroundColorBelpexs,
    borderRadius: "10px",
    padding: "10px",
    marginTop: "-140px",
  };

  // Styles for beurs data feiten
  const stylesBelix = {
    fontSize: "40px",
    marginRight: "20px",
    color: "#ffffff",
    backgroundColor: backgroundColorBelix,
    borderRadius: "10px",
    padding: "10px",
    marginTop: "-140px",
  };

  // Styles for beurs data feiten
  const stylesDam = {
    fontSize: "40px",
    marginRight: "20px",
    color: "#ffffff",
    backgroundColor: backgroundColorDam,
    borderRadius: "10px",
    padding: "10px",
    marginTop: "-140px",
  };

  // Styles for beurs data feiten
  const stylesZtp = {
    fontSize: "40px",
    marginRight: "20px",
    color: "#ffffff",
    backgroundColor: backgroundColorZtp,
    borderRadius: "10px",
    padding: "10px",
    marginTop: "-140px",
  };

  const bottomBorderStylesBelpex: React.CSSProperties = {
    content: "",
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "5px", // Adjust this value to control the thickness of the border
    backgroundColor: backgroundColorBelpexs, // Color of the bottom border
    borderBottomLeftRadius: "8px", // Rounded bottom left corner
    borderBottomRightRadius: "8px", // Rounded bottom right corner
  };

  const bottomBorderStylesBelixx: React.CSSProperties = {
    content: "",
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "5px", // Adjust this value to control the thickness of the border
    backgroundColor: backgroundColorBelix, // Color of the bottom border
    borderBottomLeftRadius: "8px", // Rounded bottom left corner
    borderBottomRightRadius: "8px", // Rounded bottom right corner
  };

  const bottomBorderStylesDam: React.CSSProperties = {
    content: "",
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "5px", // Adjust this value to control the thickness of the border
    backgroundColor: backgroundColorDam, // Color of the bottom border
    borderBottomLeftRadius: "8px", // Rounded bottom left corner
    borderBottomRightRadius: "8px", // Rounded bottom right corner
  };

  const bottomBorderStylesZtp: React.CSSProperties = {
    content: "",
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "5px", // Adjust this value to control the thickness of the border
    backgroundColor: backgroundColorZtp, // Color of the bottom border
    borderBottomLeftRadius: "8px", // Rounded bottom left corner
    borderBottomRightRadius: "8px", // Rounded bottom right corner
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchBelpexData();
    fetchBelixData();
    fetchDamData();
    fetchZtpData();
  }, []);

  const fetchBelpexData = async () => {
    try {
      const belpexsDataResponse = await axios.get<BelpexData[]>(
        `${process.env.REACT_APP_DB_URL}/belpexdata`,
      );
      const belpexsData = belpexsDataResponse.data;

      setBelpexs(belpexsData);
    } catch (error) {
      console.error("Error retrieving Belpex data:", error);
    }
  };

  // Format the Date objects to display date and hour on the x-axis labels
  const xLabelFormatter = (tick: any) => {
    const tickDate = new Date(tick);
    const timezoneOffset = tickDate.getTimezoneOffset(); // Get the timezone offset in minutes
    tickDate.setTime(tickDate.getTime() + timezoneOffset * 60 * 1000); // Apply timezone offset
    return `${tickDate.toLocaleDateString()} ${tickDate.getHours()}:00`;
  };

  // Get the maximum and minimum Euro value to set the Y-axis domain with padding
  const maxEuroValue = Math.max(...belpexs.map((data) => data.Euro));
  const minEuroValue = Math.min(...belpexs.map((data) => data.Euro));
  const yAxisDomain = [
    Math.floor(minEuroValue / 100) * 100,
    Math.ceil(maxEuroValue / 100) * 100,
  ]; // Add padding to the Y-axis domain

  const fetchBelixData = async () => {
    try {
      const belixsDataResponse = await axios.get<BelixData[]>(
        `${process.env.REACT_APP_DB_URL}/belixdata`,
      );
      const belixsData = belixsDataResponse.data;

      // Group the data by Date and calculate the average Euro value for each date
      const groupedBelixs: { [date: string]: { Euro: number[] } } = {};
      belixsData.forEach((data) => {
        const dateStr = new Date(data.Date).toDateString(); // Convert the date to a string (e.g., "Sun Aug 06 2024")
        if (!groupedBelixs[dateStr]) {
          groupedBelixs[dateStr] = { Euro: [] };
        }
        groupedBelixs[dateStr].Euro.push(data.Euro);
      });

      const combinedBelixs = Object.keys(groupedBelixs).map((dateStr) => {
        const euroValues = groupedBelixs[dateStr].Euro;
        const averageEuro =
          euroValues.reduce((sum, value) => sum + value, 0) / euroValues.length;
        return {
          Date: new Date(dateStr),
          Euro: averageEuro,
        };
      });

      // Sort the data by Date in ascending order
      combinedBelixs.sort((a, b) => a.Date.getTime() - b.Date.getTime());

      // Get the latest two records
      const latestRecord = combinedBelixs[combinedBelixs.length - 1];
      const secondLatestRecord = combinedBelixs[combinedBelixs.length - 2];

      // Calculate percentage change
      const percentageChange =
        ((latestRecord.Euro - secondLatestRecord.Euro) /
          secondLatestRecord.Euro) *
        100;

      setBelixsFact(percentageChange);

      setBelixs(combinedBelixs);
    } catch (error) {
      console.error("Error retrieving documents:", error);
    }
  };

  const fetchDamData = async () => {
    try {
      const DamsDataResponse = await axios.get<DamData[]>(
        `${process.env.REACT_APP_DB_URL}/damdata`,
      );
      const damsData = DamsDataResponse.data;

      // Group the data by Date and calculate the average Euro value for each date
      const groupedDams: { [date: string]: { Euro: number[] } } = {};
      damsData.forEach((data) => {
        const dateStr = new Date(data.Date).toDateString(); // Convert the date to a string (e.g., "Sun Aug 06 2024")
        if (!groupedDams[dateStr]) {
          groupedDams[dateStr] = { Euro: [] };
        }
        groupedDams[dateStr].Euro.push(data.Euro);
      });

      const combinedDams = Object.keys(groupedDams).map((dateStr) => {
        const euroValues = groupedDams[dateStr].Euro;
        const averageEuro =
          euroValues.reduce((sum, value) => sum + value, 0) / euroValues.length;
        return {
          Date: new Date(dateStr),
          Euro: averageEuro,
        };
      });

      // Sort the data by Date in ascending order
      combinedDams.sort((a, b) => a.Date.getTime() - b.Date.getTime());

      // Get the latest two records
      const latestRecord = combinedDams[combinedDams.length - 1];
      const secondLatestRecord = combinedDams[combinedDams.length - 2];

      // Calculate percentage change
      const percentageChange =
        ((latestRecord.Euro - secondLatestRecord.Euro) /
          secondLatestRecord.Euro) *
        100;

      setDamFact(percentageChange);

      setDam(combinedDams);
    } catch (error) {
      console.error("Error retrieving documents:", error);
    }
  };

  const fetchZtpData = async () => {
    try {
      const ZtpsDataResponse = await axios.get<ZtpData[]>(
        `${process.env.REACT_APP_DB_URL}/ztpdata`,
      );
      const ztpsData = ZtpsDataResponse.data;

      // Group the data by Date and calculate the average Euro value for each date
      const groupedZtps: { [date: string]: { Euro: number[] } } = {};
      ztpsData.forEach((data) => {
        const dateStr = new Date(data.Date).toDateString(); // Convert the date to a string (e.g., "Sun Aug 06 2024")
        if (!groupedZtps[dateStr]) {
          groupedZtps[dateStr] = { Euro: [] };
        }
        groupedZtps[dateStr].Euro.push(data.Euro);
      });

      const combinedZtps = Object.keys(groupedZtps).map((dateStr) => {
        const euroValues = groupedZtps[dateStr].Euro;
        const averageEuro =
          euroValues.reduce((sum, value) => sum + value, 0) / euroValues.length;
        return {
          Date: new Date(dateStr),
          Euro: averageEuro,
        };
      });

      // Sort the data by Date in ascending order
      combinedZtps.sort((a, b) => a.Date.getTime() - b.Date.getTime());

      // Get the latest two records
      const latestRecord = combinedZtps[combinedZtps.length - 1];
      const secondLatestRecord = combinedZtps[combinedZtps.length - 2];

      // Calculate percentage change
      const percentageChange =
        ((latestRecord.Euro - secondLatestRecord.Euro) /
          secondLatestRecord.Euro) *
        100;

      setZtpFact(percentageChange);

      setZtp(combinedZtps);
    } catch (error) {
      console.error("Error retrieving documents:", error);
    }
  };

  const maxBelixEuroValue = Math.max(...belixs.map((data) => data.Euro));
  const minBelixEuroValue = Math.min(...belixs.map((data) => data.Euro));
  const yAxisBelixDomain = [
    Math.floor(minBelixEuroValue / 100) * 100,
    Math.ceil(maxBelixEuroValue / 100) * 100,
  ]; // Add padding to the Y-axis domain

  const maxDamEuroValue = Math.max(...dam.map((data) => data.Euro));
  const minDamEuroValue = Math.min(...dam.map((data) => data.Euro));
  const yAxisDamDomain = [
    Math.floor(minDamEuroValue / 100) * 100,
    Math.ceil(maxDamEuroValue / 100) * 100,
  ]; // Add padding to the Y-axis domain

  const maxZtpEuroValue = Math.max(...dam.map((data) => data.Euro));
  const minZtpEuroValue = Math.min(...dam.map((data) => data.Euro));
  const yAxisZtpDomain = [
    Math.floor(minZtpEuroValue / 100) * 100,
    Math.ceil(maxZtpEuroValue / 100) * 100,
  ]; // Add padding to the Y-axis domain

  const xLabelFormatterBelix = (tick: any) => {
    const tickDate = new Date(tick);
    return `${tickDate.toLocaleString("default", {
      month: "short",
      year: "numeric",
    })}`;
  };

  const xLabelFormatterDam = (tick: any) => {
    const tickDate = new Date(tick);
    return `${tickDate.toLocaleString("default", {
      month: "short",
      year: "numeric",
    })}`;
  };

  const xLabelFormatterZtp = (tick: any) => {
    const tickDate = new Date(tick);
    return `${tickDate.toLocaleString("default", {
      month: "short",
      year: "numeric",
    })}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Get the current date and calculate the date that is 3 months in the future
  const currentDate = new Date();
  const futureDate = new Date(currentDate);
  futureDate.setMonth(currentDate.getMonth() + 3);

  const xLabelFormatterWeek = (tick: any) => {
    const tickDate = new Date(tick);
    const startDateOfWeek = new Date(tickDate);
    startDateOfWeek.setDate(tickDate.getDate() - tickDate.getDay()); // Find the first day of the week

    const endDateOfWeek = new Date(startDateOfWeek);
    endDateOfWeek.setDate(startDateOfWeek.getDate() + 6); // Find the last day of the week

    return `${startDateOfWeek.toLocaleDateString()} - ${endDateOfWeek.toLocaleDateString()}`;
  };

  const xLabelFormatterMonth = (tick: any) => {
    const tickDate = new Date(tick);
    return tickDate.toLocaleString("default", {
      month: "short",
      year: "numeric",
    });
  };

  const xLabelFormatterYear = (tick: any) => {
    const tickDate = new Date(tick);
    return tickDate.getFullYear().toString();
  };

  // Function to sort data by date
  // const sortByDateBelpex = (data: BelpexData[]) => {
  //   return data.sort((a, b) => a.Date.getTime() - b.Date.getTime());
  // };
  const sortByDateBelpex = (data: BelpexData[]) => {
    return data.sort(
      (a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime(),
    );
  };

  // Function to sort data by date
  const sortByDateTTF = (data: BelixData[]) => {
    return data.sort(
      (a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime(),
    );
  };

  // Function to sort data by date
  const sortByDateDam = (data: DamData[]) => {
    return data.sort(
      (a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime(),
    );
  };

  // Function to sort data by date
  const sortByDateZTP = (data: ZtpData[]) => {
    return data.sort(
      (a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime(),
    );
  };

  const filterDataByTimePeriodBelpex = (
    data: BelpexData[],
    timePeriod: string,
  ) => {
    const currentDate = new Date();

    switch (timePeriod) {
      case "dagelijks":
        return data.filter((item) => {
          const itemDate = new Date(item.Date);
          return (
            itemDate.getFullYear() === currentDate.getFullYear() &&
            itemDate.getMonth() === currentDate.getMonth() &&
            itemDate.getDate() === currentDate.getDate()
          );
        });
      case "Wekelijks":
        const oneWeekAgo = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
        );
        return data.filter((item) => new Date(item.Date) >= oneWeekAgo);
      case "maandelijks":
        const oneMonthAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate(),
        );
        return data.filter((item) => new Date(item.Date) >= oneMonthAgo);
      case "jaarlijks":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate(),
        );
        return data.filter((item) => new Date(item.Date) >= oneYearAgo);
      default:
        return data;
    }
  };

  const filterDataByTimePeriodTTF = (data: BelixData[], timePeriod: string) => {
    const currentDate = new Date();

    switch (timePeriod) {
      case "dagelijks":
        return data.filter((item) => {
          const itemDate = new Date(item.Date);
          return (
            itemDate.getFullYear() === currentDate.getFullYear() &&
            itemDate.getMonth() === currentDate.getMonth() &&
            itemDate.getDate() === currentDate.getDate()
          );
        });
      case "Wekelijks":
        const oneWeekAgo = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
        );
        return data.filter((item) => new Date(item.Date) >= oneWeekAgo);
      case "maandelijks":
        const oneMonthAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate(),
        );
        return data.filter((item) => new Date(item.Date) >= oneMonthAgo);
      case "jaarlijks":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate(),
        );
        return data.filter((item) => new Date(item.Date) >= oneYearAgo);
      default:
        return data;
    }
  };

  const filterDataByTimePeriodDam = (data: DamData[], timePeriod: string) => {
    const currentDate = new Date();

    switch (timePeriod) {
      case "dagelijks":
        return data.filter((item) => {
          const itemDate = new Date(item.Date);
          return (
            itemDate.getFullYear() === currentDate.getFullYear() &&
            itemDate.getMonth() === currentDate.getMonth() &&
            itemDate.getDate() === currentDate.getDate()
          );
        });
      case "Wekelijks":
        const oneWeekAgo = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
        );
        return data.filter((item) => new Date(item.Date) >= oneWeekAgo);
      case "maandelijks":
        const oneMonthAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate(),
        );
        return data.filter((item) => new Date(item.Date) >= oneMonthAgo);
      case "jaarlijks":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate(),
        );
        return data.filter((item) => new Date(item.Date) >= oneYearAgo);
      default:
        return data;
    }
  };
  const filterDataByTimePeriodZTP = (data: ZtpData[], timePeriod: string) => {
    const currentDate = new Date();

    switch (timePeriod) {
      case "dagelijks":
        return data.filter((item) => {
          const itemDate = new Date(item.Date);
          return (
            itemDate.getFullYear() === currentDate.getFullYear() &&
            itemDate.getMonth() === currentDate.getMonth() &&
            itemDate.getDate() === currentDate.getDate()
          );
        });
      case "Wekelijks":
        const oneWeekAgo = new Date(
          currentDate.getTime() - 7 * 24 * 60 * 60 * 1000,
        );
        return data.filter((item) => new Date(item.Date) >= oneWeekAgo);
      case "maandelijks":
        const oneMonthAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate(),
        );
        return data.filter((item) => new Date(item.Date) >= oneMonthAgo);
      case "jaarlijks":
        const oneYearAgo = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate(),
        );
        return data.filter((item) => new Date(item.Date) >= oneYearAgo);
      default:
        return data;
    }
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Beursinformatie</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationCustomer
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <CenterTitle
                title="Beursinformatie"
                subtitle="Hier kan u alle belangrijke informatie terugvinden over de belangrijkste energiebeurzen met betrekking op uw prijzen."
              ></CenterTitle>

              <div
                className="dashboardContainer"
                style={dashboardContainerStyles}
              >
                <div style={chartContainerStyles}>
                  <div className="square" style={chartStyles}>
                    <p>Belpex Index (Elektriciteit): </p>
                    {/* Render the dropdown for the first chart */}
                    <select
                      value={selectedTimePeriodBelpex}
                      onChange={(event) =>
                        setSelectedTimePeriodBelpex(event.target.value)
                      }
                    >
                      <option value="dagelijks">Dagelijks</option>
                      <option value="Wekelijks">Wekelijks</option>
                      <option value="maandelijks">Maandelijks</option>
                      <option value="jaarlijks">Jaarlijks</option>
                    </select>

                    <ResponsiveContainer width={screenWidth > 500 ? 700 : 280}>
                      <BarChart
                        data={filterDataByTimePeriodBelpex(
                          sortByDateBelpex(belpexs),
                          selectedTimePeriodBelpex,
                        )}
                      >
                        <defs>
                          <linearGradient
                            id="colorBel"
                            x1="1"
                            y1="0"
                            x2="0"
                            y2="0"
                          >
                            <stop
                              offset="30%"
                              stopColor="#269B66"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="100%"
                              stopColor="#87BD45"
                              stopOpacity={0.2}
                            />
                          </linearGradient>
                        </defs>

                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="Date"
                          tickFormatter={(value, index) => {
                            if (selectedTimeframe === "day") {
                              return xLabelFormatter(value);
                            } else if (selectedTimeframe === "week") {
                              return xLabelFormatterWeek(value);
                            } else if (selectedTimeframe === "month") {
                              // Use xLabelFormatterMonth for months
                              return xLabelFormatterMonth(value);
                            } else if (selectedTimeframe === "year") {
                              // Use xLabelFormatterYear for years
                              return xLabelFormatterYear(value);
                            } else {
                              // Handle other cases or return a default formatter
                              return ""; // Modify this based on your specific requirement
                            }
                          }}
                        />
                        <YAxis domain={yAxisDomain} />
                        <Tooltip />
                        <Legend />
                        <Bar
                          dataKey="Euro"
                          fill="url(#colorBel)"
                          fillOpacity={1}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div style={chartContainerStyles}>
                  <div
                    className="square"
                    style={{ ...squareStyles, ...chartStyles }}
                  >
                    <p>TTF index (Gas): </p>
                    {/* <select
  value={selectedTimePeriodTTF}
  onChange={event => setSelectedTimePeriodTTF(event.target.value)}
>
   <option value="dagelijks">Dagelijks</option>
  <option value="Wekelijks">Wekelijks</option> 
  <option value="maandelijks">Maandelijks</option>
  <option value="jaarlijks">Jaarlijks</option>
</select> */}
                    <ResponsiveContainer width={screenWidth > 500 ? 700 : 280}>
                      <AreaChart
                        // data={filterDataByTimePeriodTTF(sortByDateTTF(belixs), selectedTimePeriodTTF)} // Use belixs data for the AreaChart
                        data={belixs} // Use belixs data for the AreaChart
                        margin={{
                          top: 10,
                          right: 20,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="Date"
                          tickFormatter={xLabelFormatterBelix} // Use the modified formatter
                          domain={["dataMin", "dataMax"]} // Adjust the domain for the x-axis
                          scale="time" // Use time scale for the x-axis
                          padding={{ left: -17, right: 17 }} // Add or remove padding as needed
                        />
                        <YAxis domain={yAxisBelixDomain} />
                        <Tooltip />
                        <Legend />
                        <Area
                          type="monotone"
                          dataKey="Euro" // Use Euro data for the y-axis values
                          stroke="#8dc63f"
                          fill="url(#colorBel)"
                          fillOpacity={1}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>

              <div
                className="dashboardContainer"
                style={dashboardContainerStyles}
              >
                <div style={chartContainerStyles}>
                  <div
                    className="square"
                    style={{ ...squareStyles, ...chartStyles }}
                  >
                    <p>Dam index: </p>
                    <ResponsiveContainer width={screenWidth > 500 ? 700 : 280}>
                      <AreaChart
                        data={dam} // Use belixs data for the AreaChart
                        margin={{
                          top: 10,
                          right: 20,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="Date"
                          tickFormatter={xLabelFormatterDam} // Use the modified formatter
                          domain={["dataMin", "dataMax"]} // Adjust the domain for the x-axis
                          scale="time" // Use time scale for the x-axis
                          padding={{ left: -17, right: 17 }} // Add or remove padding as needed
                        />
                        <YAxis domain={yAxisDamDomain} />
                        <Tooltip />
                        <Legend />
                        <Area
                          type="monotone"
                          dataKey="Euro" // Use Euro data for the y-axis values
                          stroke="#8dc63f"
                          fill="url(#colorBel)"
                          fillOpacity={1}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                <div style={chartContainerStyles}>
                  <div
                    className="square"
                    style={{ ...squareStyles, ...chartStyles }}
                  >
                    <p>Ztp index: </p>
                    <ResponsiveContainer width={screenWidth > 500 ? 700 : 280}>
                      <AreaChart
                        data={ztp} // Use belixs data for the AreaChart
                        margin={{
                          top: 10,
                          right: 20,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="Date"
                          tickFormatter={xLabelFormatterZtp} // Use the modified formatter
                          domain={["dataMin", "dataMax"]} // Adjust the domain for the x-axis
                          scale="time" // Use time scale for the x-axis
                          padding={{ left: -17, right: 17 }} // Add or remove padding as needed
                        />
                        <YAxis domain={yAxisZtpDomain} />
                        <Tooltip />
                        <Legend />
                        <Area
                          type="monotone"
                          dataKey="Euro" // Use Euro data for the y-axis values
                          stroke="#8dc63f"
                          fill="url(#colorBel)"
                          fillOpacity={1}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>

              <div
                className="dashboardContainer"
                style={dashboardContainerCouStyles}
              >
                {/* <div className="square" style={squareCouStyles}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                     <BsFillLightningChargeFill
                     style={{
                      fontSize: "40px",
                      marginRight: "20px",
                      color: "#ffffff",
                      backgroundColor: "#8dc63f",
                      borderRadius: "10px",
                      padding: "10px",
                      marginTop: '-140px'
                    }}
                    /> 
                    <div>
                      <p style={{ margin: "0" }}>Belpex in vergelijking met vorige maand</p>
                      <h2 className="counter" style={counterStyles}>
                      {"% " + belpexsFact.toFixed(2)}
                      </h2>
                    </div>
                  </div> */}
                <div className="square" style={squareCouStyles}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BsFillLightningChargeFill style={stylesBelpex} />
                    <div>
                      <p style={{ margin: "0" }}>
                        Belpex in vergelijking met vorige maand
                      </p>
                      <h2 className="counter" style={counterStyles}>
                        {arrowIconBelpexs} {belpexsFact.toFixed(2) + "% "}
                      </h2>
                    </div>
                  </div>
                  <div style={bottomBorderStylesBelpex}></div>
                </div>
                <div className="square" style={squareCouStyles}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BsFillLightningChargeFill style={stylesBelix} />
                    <div>
                      <p style={{ margin: "0" }}>
                        Endex in vergelijking met vorige maand
                      </p>
                      <h2 className="counter" style={counterStyles}>
                        {arrowIconBelix} {belixsFact.toFixed(2) + "% "}
                      </h2>
                    </div>
                  </div>
                  <div style={bottomBorderStylesBelixx}></div>
                </div>
                <div className="square" style={squareCouStyles}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IoFlame style={stylesDam} />
                    <div>
                      <p style={{ margin: "0" }}>
                        Dam in vergelijking met vorige maand
                      </p>
                      <h2 className="counter" style={counterStyles}>
                        {arrowIconDam} {damFact.toFixed(2) + "% "}
                      </h2>
                    </div>
                  </div>
                  <div style={bottomBorderStylesDam}></div>
                </div>
                <div className="square" style={squareCouStyles}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IoFlame style={stylesZtp} />
                    <div>
                      <p style={{ margin: "0" }}>
                        ZTP in vergelijking met vorige maand
                      </p>
                      <h2 className="counter" style={counterStyles}>
                        {arrowIconZtp} {ztpFact.toFixed(2) + "% "}
                      </h2>
                    </div>
                  </div>
                  <div style={bottomBorderStylesZtp}></div>
                </div>
              </div>
              {/* Mogelijke facts hier */}
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default IndexData;
