import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../components/customer/NavigationCustomer";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Footer from "../../../components/Footer/Footer";
import "./FilesCustomer.css"; // Add a custom CSS file for styling
import PageHero from "../../../components/Pagehero/PageHero";
import { getCookie } from "../../../cookieUtils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";




interface Document {
  id: string;
  userId: string;
  documentName: string;
  documentPath: string;
  status: number;
}

const FilesCustomer: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [showDropField, setShowDropField] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [documentToDelete, setDocumentToDelete] = useState<string | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const storedUser = getCookie("user");
      if (storedUser) {
        const user = JSON.parse(storedUser);
        const response = await axios.get(
          `${process.env.REACT_APP_DB_URL}/documents/user/${user.id}`,
        );
        const fetchedDocuments: Document[] = response.data
          .filter((document: Document) => document.status === 1)
          .map((document: Document) => ({
            ...document,
            documentPath: `${process.env.REACT_APP_DB_URL}/${document.documentPath}`,
          }));
        setDocuments(fetchedDocuments);
      }
    } catch (error) {
      console.error("Error retrieving documents:", error);
    }
  };

  const deleteDocument = async (documentId: string) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_DB_URL}/documents/${documentId}`,
        { status: 2 },
      );
      console.log("Document status updated successfully");
      fetchDocuments();
      setShowConfirmModal(false);
    } catch (error) {
      console.error("Error updating document status:", error);
    }
  };

  const handleDeleteClick = (documentId: string) => {
    setDocumentToDelete(documentId);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    if (documentToDelete) {
      deleteDocument(documentToDelete);
      setDocumentToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setDocumentToDelete(null);
    setShowConfirmModal(false);
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleButtonClick = () => {
    setShowDropField(!showDropField);
  };

  const downloadDocument = (documentPath: string) => {
    window.open(documentPath, "_blank");
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const storedUser = getCookie("user");

    if (!storedUser) return;

    const user = JSON.parse(storedUser);
    const formData = new FormData();
    let invalidFileDetected = false;

    acceptedFiles.forEach((file) => {
      const validFileTypes = [
        "application/pdf",
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/webp",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      if (validFileTypes.includes(file.type)) {
        formData.append("document", file);
        formData.append("documentName", file.name);
      } else {
        invalidFileDetected = true;
      }
    });

    if (invalidFileDetected) {
      setErrorMessage("Invalid file format. Only PDF, JPG, JPEG, PNG, WEBP, DOC, DOCX, XLS, and XLSX files are allowed.");
      setShowErrorModal(true);
      return;
    }

    try {
      formData.append("userId", user.id);
      await axios.post(`${process.env.REACT_APP_DB_URL}/documents`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      fetchDocuments();
      setShowDropField(false);
    } catch (error) {
      console.error("Error uploading files:", error);
      setErrorMessage("An error occurred while uploading the file.");
      setShowErrorModal(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const closeErrorModal = () => {
    setShowErrorModal(false);
    setErrorMessage("");
  };

  return (
    <>
      {roleId === 2 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Mijn bestanden</PageHero>
          <div className="pageContainer">
            <NavigationCustomer isMenuVisible={isMenuVisible} toggleMenu={handleToggleMenu} />
            <div className="contentContainer">
              <div>
                <button className="uploadButton" onClick={handleButtonClick}>
                  {showDropField ? "Annuleren" : "+"}
                </button>
              </div>
              {showDropField && (
                <div {...getRootProps()} className={`dropzone ${isDragActive ? "active" : ""}`}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here...</p>
                  ) : (
                    <p>Klik op deze tekst om een bestand te selecteren of sleep het document in dit vak.</p>
                  )}
                </div>
              )}
              <div className="documentGrid">
                {documents.map((document) => (
                  <div key={document.id} className="documentCard">
                    <a href={document.documentPath} target="_blank" rel="noopener noreferrer" className="documentName">
                      {document.documentName}
                    </a>
                    <div className="documentActions">
                      <button className="deleteButton" onClick={() => handleDeleteClick(document.id)}>
                        Delete
                      </button>
                      <button className="downloadButton" onClick={() => downloadDocument(document.documentPath)}>
                        Bekijken
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div></div>
      )}

      {/* {showConfirmModal && (
        <div className="confirmationModal">
          <div className="confirmationContainer">
            <p>Weet u zeker dat u dit bestand wilt verwijderen?</p>
            <div className="confirmationButtons">
              <button className="confirmButton" onClick={handleConfirmDelete}>
                Ja
              </button>
              <button className="cancelButton" onClick={handleCancelDelete}>
                Nee
              </button>
            </div>
          </div>
        </div>
      )} */}
           <Dialog
        open={showConfirmModal}
        onClose={handleCancelDelete}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">
          Bevestigen Verwijderen
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            Weet u zeker dat u dit bestand wilt verwijderen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Nee
          </Button>
          <Button onClick={handleConfirmDelete} style={{ color: "red" }}>
            Ja
          </Button>
        </DialogActions>
      </Dialog>

      {showErrorModal && (
        <div className="confirmationModal">
          <div className="confirmationContainer">
            <p>{errorMessage}</p>
            <div className="confirmationButtons">
              <button className="cancelButton" onClick={closeErrorModal}>
                Sluiten
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FilesCustomer;


