import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import "../Articles.css";
import boilerMaintenanceImage from "./boilermaintenance.jpg";
import { getCookie } from "../../../../../cookieUtils";

const BoilerMaintenanceArticle = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div className="full-page-background">
          <NavBar toggleMenu={handleToggleMenu}  />
          <PageHeroColored>
            Waarom is het belangrijk om regelmatig ketelonderhoud te laten uitvoeren?
          </PageHeroColored>

          <div className="page-container">
            <NavigationCustomer isMenuVisible={isMenuVisible} toggleMenu={handleToggleMenu} />

            <div className="content-container">
              {/* Featured Image */}
              <div className="featured-image">
                <img src={boilerMaintenanceImage} alt="Boiler Maintenance" />
              </div>

              {/* Blog Content */}
              <div className="blog-content">
                <p>
                  <b>
                    Een goed functionerende ketel is essentieel voor een comfortabel en veilig huis. Om ervoor te zorgen dat uw ketel optimaal blijft werken, is regelmatig ketelonderhoud van groot belang. In dit artikel bespreken we waarom het cruciaal is om uw ketel periodiek te laten controleren en onderhouden door een professionele verwarmingsmonteur.
                  </b>
                </p>

                <h2>Veiligheid</h2>
                <p>
                  De veiligheid van uw huishouden staat voorop, en een slecht onderhouden ketel kan gevaarlijke situaties veroorzaken. Een van de risico's van een verwaarloosde ketel is de kans op koolmonoxidevergiftiging. Koolmonoxide is een kleurloos en reukloos gas dat kan vrijkomen als de ketel niet goed wordt verbrand. Regelmatig ketelonderhoud helpt dit risico te minimaliseren door ervoor te zorgen dat uw ketel efficiënt en veilig blijft werken.
                </p>

                <h2>Efficiëntie</h2>
                <p>
                  Een goed onderhouden ketel werkt efficiënter en verbruikt minder energie. Dit resulteert in lagere energiekosten en een kleinere ecologische voetafdruk. Tijdens het ketelonderhoud zal de verwarmingsmonteur alle componenten van de ketel controleren en eventuele problemen oplossen. Hierdoor kan uw ketel zijn optimale prestaties behouden.
                </p>

                <h2>Verlenging van de levensduur</h2>
                <p>
                  Regelmatig onderhoud kan de levensduur van uw ketel aanzienlijk verlengen. Kleine problemen worden vroegtijdig opgemerkt en gerepareerd voordat ze tot grotere en kostbaardere schade leiden. Door uw ketel goed te laten onderhouden, kunt u de levensduur ervan maximaliseren, wat uiteindelijk kosten bespaart op vervanging en reparatie.
                </p>

                <h2>Conclusie</h2>
                <p>
                  Het regelmatig laten uitvoeren van ketelonderhoud is een verstandige investering voor uw veiligheid, portemonnee en het milieu. Een goed functionerende ketel zorgt niet alleen voor een warm en comfortabel huis, maar minimaliseert ook het risico op gevaarlijke situaties zoals koolmonoxidevergiftiging. Neem contact op met een professionele verwarmingsmonteur om een afspraak te maken voor ketelonderhoud en geniet van de vele voordelen die het met zich meebrengt.
                </p>
              
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div>Access Restricted</div>
      )}
    </>
  );
};

export default BoilerMaintenanceArticle;
