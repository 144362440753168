import React, { useState, useEffect, useRef } from "react";
import NavigationLeft from "../../../components/Navigation/NavigationLeft/NavigationLeft";
import NavBar from "../../../components/Navigation/NavBar";
import axios from "axios";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentIcon from "@mui/icons-material/Assignment";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import EngineeringIcon from "@mui/icons-material/Engineering";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Cell,
  Pie,
} from "recharts";
import PageHero from "../../../components/Pagehero/PageHero";
import { getCookie } from "../../../cookieUtils";

interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  eane: string;
  eang: string;
  password: string;
  roleId: number;
  dateTimeCreated: Date;
}

interface SolarPanelRequest {
  id: string;
  userId: string;
  annualConsumption: number;
  roofingMaterial: number;
  connection: number;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  interestedInChargingStation: number;
  interestedInAirco: number;
  interestedInBattery: number;
  comment: number;
  status: number;
  datetimeCreated: Date;
}

interface BoilerMaintenanceRequest {
  id: string;
  userId: string;
  brand: string;
  type: string;
  age: number;
  fuel: number;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comment: string;
  status: number;
  datetimeCreated: Date;
}

interface SolarPanelCleaningRequest {
  id: string;
  userId: string;
  heightPanels: number;
  heightEaves: number;
  SolarPanelAmount: number;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comment: string;
  status: number;
  datetimeCreated: Date;
}

interface ChangeContractRequest {
  id: string;
  contractIdElectricity: string;
  contractIdGas: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  status: number;
  datetimeCreated: Date;
}

interface contract {
  id: string;
  supplierId: string;
  contractDetailsId: string;
  name: string;
  description: string;
  category: string;
}

interface energySupplier {
  id: string;
  name: string;
  logo: string;
  active: number;
}

interface ContractInstance {
  id: string;
  contractId: string;
  userId: string;
  startdate: Date;
  endDate: Date;
}

interface SupplierContractCount {
  name: string;
  value: number;
}

interface SupplierContractData {
  name: string;
  value: number;
}

interface AircoRequest {
  id: string;
  userId: string;
  connection: number;
  rooms: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comment: string;
  status: number;
  datetimeCreated: Date;
}

interface ChargingStationRequest {
  id: string;
  userId: string;
  connection: number;
  assembly: number;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comment: string;
  status: number;
  datetimeCreated: Date;
}

interface EmsRequest {
  id: string;
  userId: string;
  powerSolar: string;
  annualConsumption: string;
  brandConverter: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comment: string;
  status: number;
  datetimeCreated: Date;
}

interface HeatPumpRequest {
  id: string;
  userId: string;
  connection: number;
  currentHeating: number;
  annualConsumption: string;
  application: number;
  type: number;
  budget: number;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comment: string;
  status: number;
  datetimeCreated: Date;
}

interface HeatPumpBoilerRequest {
  id: string;
  userId: string;
  connection: number;
  currentHeating: number;
  annualConsumption: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comment: string;
  status: number;
  datetimeCreated: Date;
}

interface InfraredPanelRequest {
  id: string;
  userId: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comment: string;
  status: number;
  datetimeCreated: Date;
}

interface IsolationRequest {
  id: string;
  userId: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  comment: string;
  status: number;
  datetimeCreated: Date;
}

const IndexAdministrator: React.FC = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [aircoRequests, setAircoRequests] = useState<AircoRequest[]>([]);
  const [chargingStationRequests, setChargingStationRequests] = useState<
    ChargingStationRequest[]
  >([]);
  const [emsRequests, setEmsRequests] = useState<EmsRequest[]>([]);
  const [heatPumpRequests, setHeatPumpRequests] = useState<HeatPumpRequest[]>(
    [],
  );
  const [heatPumpBoilerRequests, setHeatPumpBoilerRequests] = useState<
    HeatPumpBoilerRequest[]
  >([]);
  const [infraredPanelRequests, setInfraredPanelRequests] = useState<
    InfraredPanelRequest[]
  >([]);
  const [isolationRequests, setIsolationsRequests] = useState<
    IsolationRequest[]
  >([]);
  const [solarPanelRequests, setSolarPanelRequests] = useState<
    SolarPanelRequest[]
  >([]);
  const [solarPanelCleaningRequests, setSolarPanelCleaningRequests] = useState<
    SolarPanelCleaningRequest[]
  >([]);
  const [boilerMaintenanceRequests, setBoilerMaintenanceRequests] = useState<
    BoilerMaintenanceRequest[]
  >([]);
  const [ChangeContractRequests, setChangeContractRequests] = useState<
    ChangeContractRequest[]
  >([]);
  const [contracts, setContracts] = useState<contract[]>([]);
  const [energysuppliers, setEnergySuppliers] = useState<energySupplier[]>([]);
  const [contractInstances, setContractInstances] = useState<
    ContractInstance[]
  >([]);
  const [selectedPeriod, setSelectedPeriod] = useState("1 day");
  const [userRegistrationData, setUserRegistrationData] = useState<
    { month: string; value: number }[]
  >([]);
  const [changeContractData, setChangeContractData] = useState<
    { month: string; value: number }[]
  >([]);
  const [requestData, setRequestData] = useState<
    { month: string; value: number }[]
  >([]);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [contractSupplierData, setContractSupplierData] = useState<
    SupplierContractCount[]
  >([]);

  // const [requestData, setRequestData] = useState<{ month: string; value: number }[]>([
  //   { month: "Januari", value: 10 },
  //   { month: "Februari", value: 5 },
  //   // Add more data points as needed
  // ]);

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const dashboardContainerStyles: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  };

  const squareStyles: React.CSSProperties = {
    // flex: "1",
    // height: "150px",
    // backgroundColor: "#ffffff",
    // borderRadius: "8px",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "left",
    // marginLeft: "10px",
    // marginRight: "10px",
    // alignItems: "flex-start",
    // paddingLeft: "10px",
    // position: "relative",
    // paddingTop: "15px",
    // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add box shadow
    flex: "1",
    height: "120px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "20px",
    marginTop: "30px",
    alignItems: "flex-start",
    paddingLeft: "10px",
    position: "relative",
    paddingTop: "15px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow
  };

  // Add a new CSS class for the bottom border
  const bottomBorderStyles: React.CSSProperties = {
    content: "",
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "5px", // Adjust this value to control the thickness of the border
    backgroundColor: "#8dc63f", // Color of the bottom border
    borderBottomLeftRadius: "8px", // Rounded bottom left corner
    borderBottomRightRadius: "8px", // Rounded bottom right corner
  };

  const graphicStyles: React.CSSProperties = {
    flex: "1",
    height: "150px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    marginLeft: "10px",
    marginRight: "10px",
    alignItems: "flex-start",
    paddingLeft: "10px",
    position: "relative",
    paddingTop: "15px",
  };

  const dropdownStyles: React.CSSProperties = {
    position: "absolute",
    bottom: "5px",
    right: "5px",
    fontSize: "14px",
  };

  const counterStyles: React.CSSProperties = {
    fontSize: "36px",
    fontWeight: "bold",
  };

  const chartContainerStyles: React.CSSProperties = {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    // flexDirection: "column",
    marginBottom: "20px",
    background: "#ffffff",
    borderRadius: "8px",
    alignItems: "center",
    marginLeft: "10px", // Add margin-left
    marginRight: "10px", // Add margin-right
    paddingBottom: "50px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow
  };

  const chartStyles: React.CSSProperties = {
    flex: "1",
    height: "400px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    paddingLeft: "10px",
    position: "relative",
    paddingTop: "15px",
    margin: "0 auto", // Center the charts within the squares
    alignItems: "center", // Center the charts horizontally
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };
  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPeriod(event.target.value);
  };

  // Define the fetchData function to fetch data from the server
  const fetchData = async () => {
    try {
      // Fetch user data
      const usersResponse = await axios.get<User[]>(
        `${process.env.REACT_APP_DB_URL}/users`,
      );
      setUsers(usersResponse.data);

      // Fetch airco requests
      const AircoRequestsResponse = await axios.get<AircoRequest[]>(
        `${process.env.REACT_APP_DB_URL}/AircoRequest`,
      );
      setAircoRequests(AircoRequestsResponse.data);

      // Fetch Charging Station requests
      const ChargingStationRequestsResponse = await axios.get<
        ChargingStationRequest[]
      >(`${process.env.REACT_APP_DB_URL}/ChargingStationRequest`);
      setChargingStationRequests(ChargingStationRequestsResponse.data);

      // Fetch EMS requests
      const EmsRequestsResponse = await axios.get<EmsRequest[]>(
        `${process.env.REACT_APP_DB_URL}/EmsRequest`,
      );
      setEmsRequests(EmsRequestsResponse.data);

      // Fetch Heat Pump requests
      const HeatPumpRequestsResponse = await axios.get<HeatPumpRequest[]>(
        `${process.env.REACT_APP_DB_URL}/HeatPumpRequest`,
      );
      setHeatPumpRequests(HeatPumpRequestsResponse.data);

      // Fetch Heat Pump Boiler requests
      const HeatPumpBoilerRequestsResponse = await axios.get<
        HeatPumpBoilerRequest[]
      >(`${process.env.REACT_APP_DB_URL}/HeatPumpBoilerRequest`);
      setHeatPumpBoilerRequests(HeatPumpBoilerRequestsResponse.data);

      // Fetch Infrared Panel requests
      const InfraredPanelRequestsResponse = await axios.get<
        InfraredPanelRequest[]
      >(`${process.env.REACT_APP_DB_URL}/InfraredPanelRequest`);
      setInfraredPanelRequests(InfraredPanelRequestsResponse.data);

      // Fetch Isolation requests
      const IsolationRequestsResponse = await axios.get<IsolationRequest[]>(
        `${process.env.REACT_APP_DB_URL}/IsolationRequest`,
      );
      setIsolationsRequests(IsolationRequestsResponse.data);

      // Fetch solar panel requests
      const solarPanelRequestsResponse = await axios.get<SolarPanelRequest[]>(
        `${process.env.REACT_APP_DB_URL}/SolarPanelRequest`,
      );
      setSolarPanelRequests(solarPanelRequestsResponse.data);

      // Fetch solar panel cleaning requests
      const solarPanelCleaningRequestsResponse = await axios.get<
        SolarPanelCleaningRequest[]
      >(`${process.env.REACT_APP_DB_URL}/SolarPanelCleanerRequest`);
      setSolarPanelCleaningRequests(solarPanelCleaningRequestsResponse.data);

      // Fetch boiler maintenance requests
      const boilerMaintenanceRequestsResponse = await axios.get<
        BoilerMaintenanceRequest[]
      >(`${process.env.REACT_APP_DB_URL}/BoilerMaintenanceRequest`);
      setBoilerMaintenanceRequests(boilerMaintenanceRequestsResponse.data);

      const contractsResponse = await axios.get<contract[]>(
        `${process.env.REACT_APP_DB_URL}/contracts`,
      );
      setContracts(contractsResponse.data);

      const contractInstancesResponse = await axios.get<ContractInstance[]>(
        `${process.env.REACT_APP_DB_URL}/contractinstances`,
      );
      setContractInstances(contractInstancesResponse.data);

      const energySupppliersResponse = await axios.get<energySupplier[]>(
        `${process.env.REACT_APP_DB_URL}/energySuppliers`,
      );
      setEnergySuppliers(energySupppliersResponse.data);

      // Fetch change contract requests
      const changeContractRequestsResponse = await axios.get<
        ChangeContractRequest[]
      >(`${process.env.REACT_APP_DB_URL}/ChangeContractRequests`);
      setChangeContractRequests(changeContractRequestsResponse.data);

      // Create chart data for user registration
      const registrationData = new Array(12).fill(0);

      usersResponse.data.forEach((user) => {
        const month = new Date(user.dateTimeCreated).getMonth();
        registrationData[month]++;
      });

      const monthLabels = [
        "Jan",
        "Feb",
        "Maa",
        "Apr",
        "Mei",
        "Juni",
        "Juli",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dec",
      ];

      setUserRegistrationData(
        registrationData.map((value, index) => ({
          month: monthLabels[index],
          value: value,
          showInLegend: true,
        })),
      );

      const ChangeRegistrationData = new Array(12).fill(0);

      changeContractRequestsResponse.data.forEach((contract) => {
        const month = new Date(contract.datetimeCreated).getMonth();
        ChangeRegistrationData[month]++;
      });

      setChangeContractData(
        ChangeRegistrationData.map((value, index) => ({
          month: monthLabels[index],
          value: value,
          showInLegend: true,
        })),
      );
    } catch (error) {
      // Handle any errors that might occur during data fetching
      console.error("Error fetching data:", error);
    }
  };

  // useEffect to fetch data when the component mounts
  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchData();
  }, []);

  useEffect(() => {
    // This useEffect will run after the initial render and when the chart state changes
    // Create chart data for requests
    const monthLabels = [
      "Jan",
      "Feb",
      "Maa",
      "Apr",
      "Mei",
      "Juni",
      "Juli",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ];

    const requestData = new Array(12).fill(0);

    aircoRequests.forEach((request) => {
      const month = new Date(request.datetimeCreated).getMonth();
      requestData[month]++;
    });

    chargingStationRequests.forEach((request) => {
      const month = new Date(request.datetimeCreated).getMonth();
      requestData[month]++;
    });

    emsRequests.forEach((request) => {
      const month = new Date(request.datetimeCreated).getMonth();
      requestData[month]++;
    });

    heatPumpRequests.forEach((request) => {
      const month = new Date(request.datetimeCreated).getMonth();
      requestData[month]++;
    });

    heatPumpBoilerRequests.forEach((request) => {
      const month = new Date(request.datetimeCreated).getMonth();
      requestData[month]++;
    });

    infraredPanelRequests.forEach((request) => {
      const month = new Date(request.datetimeCreated).getMonth();
      requestData[month]++;
    });

    isolationRequests.forEach((request) => {
      const month = new Date(request.datetimeCreated).getMonth();
      requestData[month]++;
    });

    solarPanelRequests.forEach((request) => {
      const month = new Date(request.datetimeCreated).getMonth();
      requestData[month]++;
    });

    solarPanelCleaningRequests.forEach((request) => {
      const month = new Date(request.datetimeCreated).getMonth();
      requestData[month]++;
    });

    boilerMaintenanceRequests.forEach((request) => {
      const month = new Date(request.datetimeCreated).getMonth();
      requestData[month]++;
    });

    // Update the chart data in the state
    setRequestData(
      requestData.map((value, index) => ({
        month: monthLabels[index],
        value: value,
        showInLegend: true,
      })),
    );
  }, [
    aircoRequests,
    chargingStationRequests,
    emsRequests,
    heatPumpRequests,
    heatPumpBoilerRequests,
    infraredPanelRequests,
    isolationRequests,
    solarPanelRequests,
    solarPanelCleaningRequests,
    boilerMaintenanceRequests,
    userRegistrationData,
  ]);

  // useEffect to update the pie chart data when the contractInstances, contracts, and energysuppliers change
  //  useEffect(() => {
  //   // This useEffect will run after the initial render and whenever contractInstances, contracts, or energysuppliers change
  //   // Create chart data for contract instances per supplier
  //   if (contractInstances.length > 0 && contracts.length > 0 && energysuppliers.length > 0) {
  //     const contractSupplierData: SupplierContractCount[] = [];
  //     const supplierIds = energysuppliers.map((supplier) => supplier.id);

  //     supplierIds.forEach((supplierId) => {
  //       const supplierName = energysuppliers.find(
  //         (supplier) => supplier.id === supplierId
  //       )?.name;

  //       const supplierContractInstances = contractInstances.filter(
  //         (instance) => {
  //           const contract = contracts.find(
  //             (contract) => contract.id === instance.contractId
  //           );
  //           return contract?.supplierId === supplierId;
  //         }
  //       );

  //       contractSupplierData.push({
  //         name: `${supplierName}: ${supplierContractInstances.length}`,
  //         value: supplierContractInstances.length,
  //       });
  //     });

  //     // setContractSupplierData(contractSupplierData);

  //   }
  // }, [contractInstances, contracts, energysuppliers]);

  useEffect(() => {
    // This useEffect will run after the initial render and whenever contractInstances, contracts, or energysuppliers change
    // Create chart data for contract instances per supplier
    if (
      contractInstances.length > 0 &&
      contracts.length > 0 &&
      energysuppliers.length > 0
    ) {
      const contractSupplierData: SupplierContractData[] = [];
      const supplierIds = energysuppliers.map((supplier) => supplier.id);

      supplierIds.forEach((supplierId) => {
        const supplierName = energysuppliers.find(
          (supplier) => supplier.id === supplierId,
        )?.name;

        const supplierContractInstances = contractInstances.filter(
          (instance) => {
            const contract = contracts.find(
              (contract) => contract.id === instance.contractId,
            );
            return contract?.supplierId === supplierId;
          },
        );

        contractSupplierData.push({
          name: supplierName || "Unknown Supplier", // Use "Unknown Supplier" if supplierName is not available
          value: supplierContractInstances.length,
        });
      });

      setContractSupplierData(contractSupplierData);
    }
  }, [contractInstances, contracts, energysuppliers]);

  const filterUsersByPeriod = (users: User[], period: string): User[] => {
    const currentDate = new Date();
    let startDate = new Date();

    switch (period) {
      case "1 day":
        startDate.setDate(currentDate.getDate() - 1);
        break;
      case "1 week":
        startDate.setDate(currentDate.getDate() - 7);
        break;
      case "1 month":
        startDate.setMonth(currentDate.getMonth() - 1);
        break;
      case "3 months":
        startDate.setMonth(currentDate.getMonth() - 3);
        break;
      case "6 months":
        startDate.setMonth(currentDate.getMonth() - 6);
        break;
      case "1 year":
        startDate.setFullYear(currentDate.getFullYear() - 1);
        break;
      default:
        startDate.setDate(currentDate.getDate() - 1);
        break;
    }

    const startTime = startDate.getTime(); // Convert to timestamp

    return users.filter((user) => {
      const userTime = new Date(user.dateTimeCreated).getTime(); // Convert to timestamp
      return userTime >= startTime;
    });
  };

  const filteredUsers = filterUsersByPeriod(users, selectedPeriod);
  const newUserCount = filteredUsers.length;

  // Count the total number of requests with status 1 for each type
  const totalAircoRequests = filterRequestsByStatus(aircoRequests, 1).length;

  const totalChargingStationRequests = filterRequestsByStatus(
    chargingStationRequests,
    1,
  ).length;

  const totalEmsRequests = filterRequestsByStatus(emsRequests, 1).length;

  const totalHeatPumpRequests = filterRequestsByStatus(
    heatPumpBoilerRequests,
    1,
  ).length;

  const totalHeatPumpBoilerRequests = filterRequestsByStatus(
    heatPumpBoilerRequests,
    1,
  ).length;

  const totalInfraredPanelRequests = filterRequestsByStatus(
    infraredPanelRequests,
    1,
  ).length;

  const totalIsolationRequests = filterRequestsByStatus(
    isolationRequests,
    1,
  ).length;
  const totalSolarPanelRequests = filterRequestsByStatus(
    solarPanelRequests,
    1,
  ).length;
  const totalSolarPanelCleaningRequests = filterRequestsByStatus(
    solarPanelCleaningRequests,
    1,
  ).length;
  const totalBoilerMaintenanceRequests = filterRequestsByStatus(
    boilerMaintenanceRequests,
    1,
  ).length;

  // Helper function to filter AircoRequest by status
  function filterRequestsByStatus(
    requests: AircoRequest[],
    status: number,
  ): AircoRequest[];
  // Helper function to filter ChargingStation by status
  function filterRequestsByStatus(
    requests: ChargingStationRequest[],
    status: number,
  ): ChargingStationRequest[];
  // Helper function to filter EmsRequest by status
  function filterRequestsByStatus(
    requests: EmsRequest[],
    status: number,
  ): EmsRequest[];
  // Helper function to filter HeatPumpRequest by status
  function filterRequestsByStatus(
    requests: HeatPumpRequest[],
    status: number,
  ): HeatPumpRequest[];
  // Helper function to filter HeatPumpBoiler by status
  function filterRequestsByStatus(
    requests: HeatPumpBoilerRequest[],
    status: number,
  ): HeatPumpBoilerRequest[];
  // Helper function to filter Infrared by status
  function filterRequestsByStatus(
    requests: InfraredPanelRequest[],
    status: number,
  ): InfraredPanelRequest[];
  // Helper function to filter SolarPanelRequests by status
  function filterRequestsByStatus(
    requests: IsolationRequest[],
    status: number,
  ): IsolationRequest[];
  // Helper function to filter SolarPanelRequests by status
  function filterRequestsByStatus(
    requests: SolarPanelRequest[],
    status: number,
  ): SolarPanelRequest[];
  // Helper function to filter BoilerMaintenanceRequests by status
  function filterRequestsByStatus(
    requests: BoilerMaintenanceRequest[],
    status: number,
  ): BoilerMaintenanceRequest[];
  // Helper function to filter SolarPanelCleaningRequests by status
  function filterRequestsByStatus(
    requests: SolarPanelCleaningRequest[],
    status: number,
  ): SolarPanelCleaningRequest[];
  // Implementation of the function
  function filterRequestsByStatus(requests: any[], status: number) {
    return requests.filter((request) => request.status === status);
  }

  return (
    <>
      {roleId === 1 ? (
        <div>
          {/* <script src="https://code.highcharts.com/highcharts.js"></script> */}
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Dashboard</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div
                className="dashboardContainer"
                style={dashboardContainerStyles}
              >
                <div className="square" style={squareStyles}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AssignmentIndIcon
                      style={{
                        fontSize: "40px",
                        marginRight: "20px",
                        color: "#ffffff",
                        backgroundColor: "#8dc63f",
                        borderRadius: "10px",
                        padding: "10px",
                        marginTop: "-150px",
                      }}
                    />
                    <div>
                      <p style={{ margin: "0" }}>Aantal nieuwe gebruikers</p>
                      <h2 className="counter" style={counterStyles}>
                        {newUserCount}
                      </h2>
                    </div>
                  </div>
                  <select
                    className="dropdown"
                    style={dropdownStyles}
                    value={selectedPeriod}
                    onChange={handlePeriodChange}
                  >
                    <option value="1 day">Vandaag</option>
                    <option value="1 week">1 week</option>
                    <option value="1 month">1 maand</option>
                    <option value="3 months">3 maanden</option>
                    <option value="6 months">6 maanden</option>
                    <option value="1 year">1 jaar</option>
                  </select>
                  <div style={bottomBorderStyles}></div>
                </div>
                <div className="square" style={squareStyles}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <WbTwilightIcon
                      style={{
                        fontSize: "40px",
                        marginRight: "20px",
                        color: "#ffffff",
                        backgroundColor: "#8dc63f",
                        borderRadius: "10px",
                        padding: "10px",
                        marginTop: "-150px",
                      }}
                    />
                    <div>
                      <p style={{ margin: "0" }}>
                        Nieuwe Zonnepanelen en thuisbatterij aanvragen
                      </p>
                      <h2 className="counter" style={counterStyles}>
                        {totalSolarPanelRequests}
                      </h2>
                    </div>
                  </div>
                  <div style={bottomBorderStyles}></div>
                </div>
                <div className="square" style={squareStyles}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CleaningServicesIcon
                      style={{
                        fontSize: "40px",
                        marginRight: "20px",
                        color: "#ffffff",
                        backgroundColor: "#8dc63f",
                        borderRadius: "10px",
                        padding: "10px",
                        marginTop: "-150px",
                      }}
                    />
                    <div>
                      <p style={{ margin: "0" }}>Nieuwe kuis aanvragen</p>
                      <h2 className="counter" style={counterStyles}>
                        {totalSolarPanelCleaningRequests}
                      </h2>
                    </div>
                  </div>
                  <div style={bottomBorderStyles}></div>
                </div>
                <div className="square" style={squareStyles}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <EngineeringIcon
                      style={{
                        fontSize: "40px",
                        marginRight: "20px",
                        color: "#ffffff",
                        backgroundColor: "#8dc63f",
                        borderRadius: "10px",
                        padding: "10px",
                        marginTop: "-150px",
                      }}
                    />
                    <div>
                      <p style={{ margin: "0" }}>
                        Nieuwe ketel onderhoud aanvragen
                      </p>
                      <h2 className="counter" style={counterStyles}>
                        {totalBoilerMaintenanceRequests}
                      </h2>
                    </div>
                  </div>
                  <div style={bottomBorderStyles}></div>
                </div>
              </div>
              {/* Charts */}
              <div
                className="dashboardContainer"
                style={dashboardContainerStyles}
              >
                <div style={chartContainerStyles}>
                  <div
                    className="square"
                    style={{ ...graphicStyles, ...chartStyles }}
                  >
                    <div
                      className="square"
                      style={{ ...graphicStyles, ...chartStyles }}
                    >
                      <p>Aantal offerteaanvragen (2024): </p>
                      <BarChart width={700} height={350} data={requestData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="value" fill="#8dc63f" />
                      </BarChart>
                    </div>
                  </div>
                </div>
                <div style={chartContainerStyles}>
                  <div
                    className="square"
                    style={{ ...graphicStyles, ...chartStyles }}
                  >
                    <p>Nieuwe geregistreerde gebruikers (2024): </p>
                    <BarChart
                      width={700}
                      height={350}
                      data={userRegistrationData}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="value" fill="#8dc63f" />
                    </BarChart>
                  </div>
                </div>
              </div>

              {/* Charts */}
              <div
                className="dashboardContainer"
                style={dashboardContainerStyles}
              >
                <div style={chartContainerStyles}>
                  <div
                    className="square"
                    style={{ ...graphicStyles, ...chartStyles }}
                  >
                    <div
                      className="square"
                      style={{ ...graphicStyles, ...chartStyles }}
                    >
                      <p>
                        Aantal aanvragen voor nieuwe energiecontracten (2024):{" "}
                      </p>
                      <BarChart
                        width={700}
                        height={350}
                        data={changeContractData}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="value" fill="#8dc63f" />
                      </BarChart>
                    </div>
                  </div>
                </div>
                <div style={chartContainerStyles}>
                  <div
                    className="square"
                    style={{ ...graphicStyles, ...chartStyles }}
                  >
                    <p>Energiecontracten in beheer per leverancier: </p>
                    <PieChart width={700} height={350}>
                      <Pie
                        data={contractSupplierData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={120}
                        innerRadius={80}
                        fill="#8884d8"
                        label
                      >
                        {contractSupplierData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={`#${Math.floor(
                              Math.random() * 16777215,
                            ).toString(16)}`}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default IndexAdministrator;
