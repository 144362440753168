import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AddUser.css";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import { IoMenu } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import KeyIcon from "@mui/icons-material/Key";
import HouseIcon from "@mui/icons-material/House";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import { IoKeySharp, IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import MenuItem from "@mui/material/MenuItem";
import PageHero from "../../../../components/Pagehero/PageHero";
import { getCookie } from "../../../../cookieUtils";

const AddUser = () => {
  const [companyUserId, setCompanyUserId] = useState("");
  const [surname, setSurname] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [streetname, setStreetname] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  // const [eane, setEane] = useState('');
  // const [eang, setEang] = useState('');
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("Customer");
  const [referralOf, setReferralOf] = useState("Energyneeds");
  const [errorMessage, setErrorMessage] = useState("");
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleSurnameChange = (event: any) => {
    setSurname(event.target.value);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event: any) => {
    setPhone(event.target.value);
  };
  const handleStreetnameChange = (event: any) => {
    setStreetname(event.target.value);
  };

  const handleHousenumberChange = (event: any) => {
    setHousenumber(event.target.value);
  };

  const handleZipcodeChange = (event: any) => {
    setZipcode(event.target.value);
  };

  const handleCityChange = (event: any) => {
    setCity(event.target.value);
  };

  // const handleEaneChange = (event: any) => {
  //   setEane(event.target.value);
  // };

  // const handleEangChange = (event: any) => {
  //   setEang(event.target.value);
  // };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const handleRoleChange = (value: any) => {
    setRole(value);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleAddUser = async () => {
    const roleId = role === "Administrator" ? 1 : 2;
    try {
      const newUser = {
        companyUserId,
        surname,
        name,
        email,
        phone,
        streetname,
        housenumber,
        zipcode,
        city,
        referralOf,
        password,
        roleId,
      };
      await axios.post(`${process.env.REACT_APP_DB_URL}/users`, newUser);
      console.log("User created successfully");
      navigate("/Users");
    } catch (error) {
      console.error("Error creating user:", error);
      // Handle error state or display an error message
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const containerStyles = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Gebruiker toevoegen</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="userCardAddUser">
                <div className="userCardContainerAddUser">
                  <div className="userCardColumnAddUser">
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <AccountCircle
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Voornaam"
                          variant="standard"
                          type="text"
                          id="surNameFilter"
                          value={surname}
                          onChange={handleSurnameChange}
                          fullWidth
                        />
                      </Box>
                    </div>

                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <AccountCircle
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Naam"
                          variant="standard"
                          type="text"
                          id="nameFilter"
                          value={name}
                          onChange={handleNameChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <AlternateEmailIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Email"
                          variant="standard"
                          type="text"
                          id="emailFilter"
                          value={email}
                          onChange={handleEmailChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <ContactPhoneIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Telefoonnummer"
                          variant="standard"
                          type="text"
                          id="phone"
                          value={phone}
                          onChange={handlePhoneChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Straatnaam"
                          variant="standard"
                          type="text"
                          id="streetname"
                          value={streetname}
                          onChange={handleStreetnameChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Huisnummer"
                          variant="standard"
                          type="text"
                          id="housenumber"
                          value={housenumber}
                          onChange={handleHousenumberChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <button className="addButton" onClick={handleAddUser}>
                        Gebruiker toevoegen
                      </button>
                    </div>
                  </div>

                  <div className="userCardColumn">
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Postcode"
                          variant="standard"
                          type="text"
                          id="zipcode"
                          value={zipcode}
                          onChange={handleZipcodeChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <HouseIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <TextField
                          label="Stad / Gemeente"
                          variant="standard"
                          type="text"
                          id="city"
                          value={city}
                          onChange={handleCityChange}
                          fullWidth
                        />
                      </Box>
                    </div>
                    {/* <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <ElectricBoltIcon
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
              />
              <TextField
                label="EAN Elektriciteit"
                variant="standard"
                type="text"
                id="eane"
                value={eane}
                onChange={handleEaneChange}
              />
            </Box>
            </div>
            <div className="form-group">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <GasMeterIcon
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
              />
              <TextField
                label="EAN Gas"
                variant="standard"
                type="text"
                id="eang"
                value={eang}
                onChange={handleEangChange}
              />
            </Box>
            </div> */}
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <KeyIcon
                          sx={{ color: "action.active", mr: 1, my: 0.5 }}
                        />
                        <FormControl variant="standard" sx={{ width: "100%" }}>
                          <InputLabel htmlFor="password">Wachtwoord</InputLabel>
                          <Input
                            type={isPasswordVisible ? "text" : "password"}
                            id="password"
                            fullWidth
                            value={password}
                            onChange={handlePasswordChange}
                            endAdornment={
                              <InputAdornment position="end">
                                {isPasswordVisible ? (
                                  <IoEyeOffSharp
                                    size={24}
                                    onClick={togglePasswordVisibility}
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <IoEyeSharp
                                    size={24}
                                    onClick={togglePasswordVisibility}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Box>
                    </div>
                    <div className="form-group">
                      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Rol"
                          value={role}
                          onChange={(e) => handleRoleChange(e.target.value)}
                          variant="standard"
                        >
                          <MenuItem value="Customer">Klant</MenuItem>

                          <MenuItem value="Administrator">
                            Administrator
                          </MenuItem>
                        </TextField>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default AddUser;
