import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { Divider, Typography } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import React from "react";
import { useNavigate } from "react-router";

interface ArticleCardProps {
  image: string;
  title: string;
  description: string;
  link: string; // New property for navigation
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  image,
  title,
  description,
  link,
}) => {
  const navigate = useNavigate();

  const navigateToArticle = () => {
    navigate(link);
  };

  return (
    <div className="square">
      <Card
        sx={{
          maxWidth: 345,
          borderRadius: "5px",
          height: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardMedia sx={{ height: "200px" }} image={image} title={title} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <div className="green-divider"></div>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <div>
          <CardActions>
            <button
              className="buttonArticle"
              onClick={navigateToArticle}
            >
              Meer informatie
            </button>
          </CardActions>
          <Divider
            sx={{
              backgroundColor: "#8dc63f",
              height: "2px",
              marginTop: "10px",
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default ArticleCard;
