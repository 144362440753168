import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import "../Articles.css";
import energyEfficientDevicesImage from "./energyefficientdevices.jpg";
import { getCookie } from "../../../../../cookieUtils";

const EnergyEfficientDevices = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div className="full-page-background">
          <NavBar toggleMenu={handleToggleMenu}  />
          <PageHeroColored>
            Waarom is het voordelig om te investeren in energiezuinige apparaten?
          </PageHeroColored>

          <div className="page-container">
            <NavigationCustomer isMenuVisible={isMenuVisible} toggleMenu={handleToggleMenu} />

            <div className="content-container">
              {/* Featured Image */}
              <div className="featured-image">
                <img src={energyEfficientDevicesImage} alt="Energy Efficient Devices" />
              </div>

              {/* Blog Content */}
              <div className="blog-content">
                <p>
                  <b>
                    Het investeren in energiezuinige apparaten is niet alleen goed voor het milieu, maar ook voor uw portemonnee. In dit artikel bespreken we de voordelen van het gebruik van energiezuinige apparaten en hoeveel u hiermee gemiddeld kunt besparen op uw energierekening.
                  </b>
                </p>

                <h2>Efficiëntie</h2>
                <p>
                  Energiezuinige apparaten werken efficiënter en verbruiken minder energie, wat resulteert in lagere energiekosten en een kleinere ecologische voetafdruk. Door te kiezen voor energiezuinige apparaten, kunt u aanzienlijk besparen op uw energierekening, vooral op de lange termijn.
                </p>

                <h2>Energiebesparing</h2>
                <p>
                  Energiezuinige apparaten zijn ontworpen om minder energie te verbruiken dan conventionele apparaten. Door de overstap te maken, kunt u tot wel 30% besparen op energiekosten, vooral op de lange termijn.
                </p>

                <h2>Milieuvriendelijk</h2>
                <p>
                  Minder energieverbruik betekent minder uitstoot van broeikasgassen, waardoor de ecologische voetafdruk verkleint. Dit draagt bij aan de strijd tegen klimaatverandering en de bescherming van natuurlijke hulpbronnen.
                </p>

                <h2>Return on Investment (ROI)</h2>
                <p>
                  Hoewel de aanschafprijs hoger kan zijn, verdient u deze kosten terug door lagere energierekeningen en een langere levensduur van de apparaten, wat leidt tot meer besparingen.
                </p>

                <h2>Conclusie</h2>
                <p>
                  Investeren in energiezuinige apparaten is goed voor uw financiën en het milieu. Hoewel initiële kosten hoger kunnen zijn, worden ze terugverdiend door besparingen en duurzaamheid.
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div>Access Restricted</div>
      )}
    </>
  );
};

export default EnergyEfficientDevices;
