import React, { useEffect, useState } from "react";
import NavBar from "../../../../../components/Navigation/NavBar";
import NavigationCustomer from "../../../../../components/customer/NavigationCustomer";
import Footer from "../../../../../components/Footer/Footer";
import PageHeroColored from "../../../../../components/PageheroColored/PageHeroColored";
import "../Articles.css";
import solarpanelsimage from "./solarpanelscleaning.jpg";
import { getCookie } from "../../../../../cookieUtils";

const SolarPanelsCleaningArticle = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));
    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }
  }, []);

  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  return (
    <>
      {roleId === 2 ? (
        <div className="full-page-background">
          <NavBar toggleMenu={handleToggleMenu}  />
          <PageHeroColored>Waarom is het belangrijk om zonnepanelen te reinigen?</PageHeroColored>

          <div className="page-container">
            <NavigationCustomer isMenuVisible={isMenuVisible} toggleMenu={handleToggleMenu} />

            <div className="content-container">
              {/* Featured Image */}
              <div className="featured-image">
                <img src={solarpanelsimage} alt="Solar Panels Cleaning" />
              </div>

              {/* Blog Content */}
              <div className="blog-content">
                <p>
                  <b>
                    Zonnepanelen zijn een uitstekende investering voor huiseigenaren en bedrijven. Ze bieden talloze voordelen, waaronder het verminderen van uw energierekening, het verminderen van uw afhankelijkheid van het elektriciteitsnet en het verminderen van uw ecologische voetafdruk.
                  </b>
                </p>

                <h2>Verbeterde efficiëntie door regelmatige reiniging</h2>
                <p>
                  Hoewel zonnepanelen ontworpen zijn om langdurig te functioneren, kunnen ze na verloop van tijd vuil, stof en andere verontreinigingen verzamelen, vooral in gebieden met veel luchtverontreiniging. Deze ophoping kan de efficiëntie van de zonnepanelen verminderen en daardoor de energieopbrengst verlagen.
                </p>

                <h2>Verlenging van de levensduur van zonnepanelen</h2>
                <p>
                  Naast het verbeteren van de efficiëntie, kan regelmatige reiniging ook de levensduur van uw zonnepanelen verlengen. Door uw zonnepanelen schoon te houden, kunt u schade minimaliseren en de levensduur van de panelen verlengen.
                </p>

                <h2>Veiligheidsoverwegingen</h2>
                <p>
                  Hoewel zonnepanelen over het algemeen veilig zijn om te gebruiken, kan vuil op de panelen bepaalde risico's met zich meebrengen. Het inschakelen van professionele zonnepaneelreinigers zorgt niet alleen voor een grondige reiniging, maar ook voor veiligheid tijdens het proces.
                </p>
                </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div>Access Restricted</div>
      )}
    </>
  );
};

export default SolarPanelsCleaningArticle;
