import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosCreate, IoIosTrash } from "react-icons/io";
import NavigationLeft from "../../../../components/Navigation/NavigationLeft/NavigationLeft";
import { colors } from "../../../../theme/colors";
import "./Users.css"; // Import the CSS file for styles
import { dividerClasses } from "@mui/material";
import { IoMenu } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../../components/Navigation/NavBar";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import Button from "@mui/material/Button";
import PageHero from "../../../../components/Pagehero/PageHero";
import * as XLSX from "xlsx";
import { getCookie } from "../../../../cookieUtils";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";



interface User {
  id: string;
  companyUserId: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  streetname: string;
  housenumber: string;
  zipcode: string;
  city: string;
  referralOf: string;
  password: string;
  roleId: number;
}

const Users = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [surNameFilter, setSurNameFilter] = useState("");
  const [nameFilter, setnameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [eaneFilter, setEaneFilter] = useState("");
  const [eangFilter, setEangFilter] = useState("");
  const [confirmDeleteUser, setConfirmDeleteUser] = useState<User | null>(null);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [roleId, setRoleId] = useState<number | null>(null);
  const navigate = useNavigate();
  const handleToggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const roleIdFromStorage = Number(getCookie("roleId"));

    if (!isNaN(roleIdFromStorage)) {
      setRoleId(roleIdFromStorage);
    }

    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get<User[]>(
        `${process.env.REACT_APP_DB_URL}/users`,
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error retrieving users:", error);
      // Handle error state or display an error message
    }
  };

  const handleEditUser = (userId: string) => {
    // Navigate to edit user page
    navigate(`/EditUser/${userId}`);
  };

  const handleDetailUser = (userId: string) => {
    // Navigate to user detail page
    navigate(`/DetailUser/${userId}`);
  };

  const handleDeleteUser = (user: User) => {
    setConfirmDeleteUser(user);
  };

  const confirmDelete = async () => {
    if (confirmDeleteUser) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_DB_URL}/users/${confirmDeleteUser.id}`,
        );
        fetchUsers();
      } catch (error) {
        console.error("Error deleting user:", error);
        // Handle error state or display an error message
      }
    }
    setConfirmDeleteUser(null);
  };

  const cancelDelete = () => {
    setConfirmDeleteUser(null);
  };

  const exportToExcel = () => {
    const dataToExport = filteredUsers.map((user) => ({
      Voornaam: user.surname,
      Achternaam: user.name,
      Email: user.email,
      Telefoonnummer: user.phone,
      Adres: `${user.streetname}, ${user.housenumber}`,
      Postcode: user.zipcode,
      Stad: user.city,
      Referral: user.referralOf,
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users");

    XLSX.writeFile(wb, "Users.xlsx");
  };

  const filteredUsers = users.filter((user: User) => {
    const surNameMatch = user.surname
      .toLowerCase()
      .includes(surNameFilter.toLowerCase());
    const nameMatch = user.name
      .toLowerCase()
      .includes(nameFilter.toLowerCase());
    const emailMatch = user.email
      .toLowerCase()
      .includes(emailFilter.toLowerCase());
    // const eaneMatch = user.eane
    //   .toLowerCase()
    //   .includes(eaneFilter.toLowerCase());
    // const eangMatch = user.eang
    //   .toLowerCase()
    //   .includes(eangFilter.toLowerCase());

    return surNameMatch && nameMatch && emailMatch;
  });

  const handleAddUser = () => {
    // Navigate to add user page
    navigate("/AddUser");
  };

  const containerStyles: React.CSSProperties = {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
  };

  const contentContainerStyles: React.CSSProperties = {
    flex: "1",
    backgroundColor: "#f8f9fa",
    boxShadow: "none", // Remove the box shadow
    padding: "20px",
    transition: "all 0.3s",
  };

  const pageTitleStyles: React.CSSProperties = {
    fontSize: "24px",
    fontWeight: "bold",
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#272727",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      {roleId === 1 ? (
        <div>
          <NavBar toggleMenu={handleToggleMenu} />
          <PageHero>Gebruikers beheren</PageHero>
          <div className="pageContainer" style={containerStyles}>
            <NavigationLeft
              isMenuVisible={isMenuVisible}
              toggleMenu={handleToggleMenu}
            />
            <div className="contentContainer" style={contentContainerStyles}>
              <div className="filterRow">
                <div className="filterContainer">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <AccountCircle
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                    <TextField
                      label="Filter op Voornaam: "
                      variant="standard"
                      type="text"
                      id="surNameFilter"
                      value={surNameFilter}
                      onChange={(e) => setSurNameFilter(e.target.value)}
                    />
                  </Box>
                </div>
                <div className="filterContainer">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <AccountCircle
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                    <TextField
                      label="Filter op Naam: "
                      variant="standard"
                      type="text"
                      id="nameFilter"
                      value={nameFilter}
                      onChange={(e) => setnameFilter(e.target.value)}
                    />
                  </Box>
                </div>
                <div className="filterContainer">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <AlternateEmailIcon
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />
                    <TextField
                      label="Filter op Email: "
                      variant="standard"
                      type="text"
                      id="emailFilter"
                      value={emailFilter}
                      onChange={(e) => setEmailFilter(e.target.value)}
                    />
                  </Box>
                </div>
                {/* <div className="filterContainer">
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <ElectricMeterIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField
                label="Filter op EAN Elektriciteit: "
                variant="standard"
                type="text"
                id="eaneFilter"
                value={eaneFilter}
                onChange={(e) => setEaneFilter(e.target.value)}
              />
            </Box>
            </div>
            <div className="filterContainer">
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <GasMeterIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField
                label="Filter op EAN Gas: "
                variant="standard"
                type="text"
                id="eangFilter"
                value={eangFilter}
                onChange={(e) => setEangFilter(e.target.value)}
              />
            </Box>
            </div> */}
                <button className="addButton" onClick={handleAddUser}>
                  Gebruiker toevoegen
                </button>
                <button className="buttonstyleR33" onClick={exportToExcel}>
                  {/* <IoIosDownload size={20} /> */}
                  Exporteer naar Excel
                </button>
              </div>

              <hr className="dashedDivider" />
              <div className="recordCount">
                <p>{` ${filteredUsers.length} van de ${users.length} records zichtbaar`}</p>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Voornaam</StyledTableCell>
                      <StyledTableCell align="right">
                        Achternaam
                      </StyledTableCell>
                      <StyledTableCell align="right">Email</StyledTableCell>
                      <StyledTableCell align="right">
                        Telefoonnummer
                      </StyledTableCell>
                      <StyledTableCell align="right">Adres</StyledTableCell>
                      <StyledTableCell align="right">Postcode</StyledTableCell>
                      <StyledTableCell align="right">Stad</StyledTableCell>
                      <StyledTableCell align="right">Referral</StyledTableCell>
                      {/* <StyledTableCell align="right">
                    EAN Elektriciteit
                  </StyledTableCell>
                  <StyledTableCell align="right">EAN Gas</StyledTableCell> */}
                      <StyledTableCell align="right">Acties</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers.map((user) => (
                      <StyledTableRow key={user.id}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          onClick={() => handleDetailUser(user.id)}
                        >
                          {user.surname}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() => handleDetailUser(user.id)}
                          align="right"
                        >
                          {user.name}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() => handleDetailUser(user.id)}
                          align="right"
                        >
                          {user.email}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() => handleDetailUser(user.id)}
                          align="right"
                        >
                          {user.phone}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() => handleDetailUser(user.id)}
                          align="right"
                        >
                          {user.streetname}, {user.housenumber}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() => handleDetailUser(user.id)}
                          align="right"
                        >
                          {user.zipcode}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() => handleDetailUser(user.id)}
                          align="right"
                        >
                          {user.city}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() => handleDetailUser(user.id)}
                          align="right"
                        >
                          {user.referralOf}
                        </StyledTableCell>
                        {/* <StyledTableCell
                      onClick={() => handleDetailUser(user.id)}
                      align="right"
                    >
                      {user.eane}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() => handleDetailUser(user.id)}
                      align="right"
                    >
                      {user.eang}
                    </StyledTableCell> */}

                        {/* <StyledTableCell onClick={() => handleDetailUser(user.id)} align="right"> {user.roleId === 1 ? 'Administrator' : user.roleId === 2 ? 'Klant' : ''}</StyledTableCell> */}
                        <StyledTableCell align="right">
                          <IoIosCreate
                            size={24}
                            className="actionIconEdit"
                            onClick={() => handleEditUser(user.id)}
                          />
                          <IoIosTrash
                            size={24}
                            className="actionIconDelete"
                            onClick={() => handleDeleteUser(user)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* {confirmDeleteUser && (
                <div className="confirmationModal">
                  <p className="confirmationText">
                    Bent u zeker dat u deze gebruiker wil verwijderen?
                  </p>
                  <div className="confirmationButtons">
                    <button className="confirmButton" onClick={confirmDelete}>
                      Delete
                    </button>
                    <button className="cancelButton" onClick={cancelDelete}>
                      Cancel
                    </button>
                  </div>
                </div>
              )} */}
                 <Dialog open={!!confirmDeleteUser} onClose={cancelDelete}>
        <DialogTitle>Bevestig Verwijderen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bent u zeker dat u deze gebruiker wilt verwijderen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Annuleren
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Verwijderen
          </Button>
        </DialogActions>
      </Dialog>
            </div>
          </div>
        </div>
      ) : (
        <div> </div>
      )}
    </>
  );
};

export default Users;
